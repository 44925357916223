const Iridology = () => {
  return (
    <section className="body-font mt-16">
      <div className="m-8 px-4">
        <div className="flex md:my-16 items-center  flex-col md:flex-row-reverse">
          <div className="md:w-1/2">
            <h2 className="mt-12 text-6xl font-bold  text-green-600">
              What is iridology or Iris analysis?
            </h2>
            <p className="text-2xl font-light mt-6">
              Iridology is the scientific analysis of pattern sand structures in
              the iris of the eye which locates areas and stages of inflammation
              throughout the body. The iris is the portion of the eye showing
              color. It reveals body constitution, inherent strengths and
              weaknesses, health levels, and transitions that take place in a
              person body according to their way of life. It is very much
              helping tool of diagnosis as per the medical science of Electro
              homoeopathy and Naturopathy.
            </p>
          </div>
          <div className="md:w-1/2 w-full my-6 md:my-6">
            <img
              src="https://thumbs.dreamstime.com/b/iridology-as-eye-iris-monitoring-disease-diagnostics-outline-diagram-iridology-as-eye-iris-monitoring-disease-diagnostics-224051906.jpg"
              alt="Iridology"
            />
          </div>
        </div>
      </div>
      {/* <div className="text-center m-8 px-4 md:px-8 pt-4 pb-20">
        <h2 className="mt-8 text-5xl font-bold text-green-600  uppercase">
          Iris Analysis
        </h2>
        <p className="text-2xl font-light mt-16">
          Iridology is a science that studies the structure of the iris in order
          to know the physical and psycho-emotional characteristics of the
          individual. The colors, shapes, designs, architectures are observed
          and they relate to the whole body through well-defined maps, so as to
          identify constitutional weaknesses, predispositions or physical
          disorders and psycho-emotional characteristics end epigenetic.
        </p>
        <p className="text-2xl font-light mt-6">
          Today, thanks to technology and the knowledge acquired in
          neurophysiology, we can also observe and study the epigenetic and
          emotional aspect that often causes physical symptoms. The iris (the
          pupil) is in direct contact with the central nervous system through
          the autonomic nervous system and the hypothalamus. The hypothalamus is
          part of both the autonomic nervous system and the limbic system.
        </p>
        <p className="text-2xl font-light mt-6">
          In the limbic system we have nervous structures capable of creating
          emotions and behaviors adapted to stimuli and personal needs. So on
          the iris it is possible to identify the deep tensions that are able to
          activate the hypothalamus-pituitary-adrenal axis (stress axis) and the
          related production of cortisol. The abnormal production of cortisol,
          in the long term, can cause diseases such as: hypertension, diabetes,
          osteoporosis and inhibition of the immune system.
        </p>
      </div> */}
    </section>
  );
};

export default Iridology;
