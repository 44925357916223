import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button, TextAreaGroup, Select } from '../elements';

const Container = styled.form``;

const EditCenterForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  packages = [],
  setFieldValue,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Center Name:"
          name="name"
          type="text"
          placeholder="Center Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="mb-4">
        <InputGroup
          label="Center Email"
          name="email"
          type="text"
          placeholder="Enter Center  Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.email && touched.email ? errors.email : undefined}
        />
      </div>
      {/* <div className="mb-6">
        <InputGroup
          label="Center Password:"
          name="password"
          type="password"
          placeholder="**************"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.password && touched.password ? errors.password : undefined
          }
        />{' '}
      </div> */}
      <div className="mb-6">
        <InputGroup
          label="Country"
          name="country"
          type="text"
          placeholder="Country"
          value={values.country}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.country && touched.country ? errors.country : undefined
          }
        />
      </div>
      <div className="mb-6">
        <InputGroup
          label="State"
          name="state"
          placeholder="state"
          type="text"
          value={values.state}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.state && touched.state ? errors.state : undefined}
        />
      </div>
      <div className="mb-6">
        <InputGroup
          label="City"
          name="city"
          placeholder="city"
          type="text"
          value={values.city}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.city && touched.city ? errors.city : undefined}
        />
      </div>
      <div className="mb-6">
        <InputGroup
          label="Phone No."
          name="phoneNumber"
          placeholder="Phone No."
          type="text"
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.phoneNumber && touched.phoneNumber
              ? errors.phoneNumber
              : undefined
          }
        />

        <div className="mb-4">
          <InputGroup
            label="Address"
            name="address"
            type="text"
            placeholder="Address"
            value={values.address}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.address && touched.address ? errors.address : undefined
            }
          />
        </div>
        <TextAreaGroup
          label="Remark"
          name="remark"
          type="text"
          placeholder="Remark"
          value={values.remark}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.remark && touched.remark ? errors.remark : undefined}
        />
        <Select
          label="Select Package"
          data={packages}
          onChange={(e) => {
            if (e && e.id) {
              setFieldValue('package', e.id);
            }
          }}
        />
        <p className="text-red-500 text-xs italic">{errors.package}</p>
      </div>

      <Button
        type="submit"
        loading={isSubmitting}
        className="mt-4 w-3/12 uppercase justify-center"
      >
        Submit
      </Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: () => ({
    name: '',
    country: '',
    email: '',
    address: '',
    phoneNumber: '',
    city: '',
    state: '',
    remark: '',
    package: '',
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Center Name is required!'),
    email: yup
      .string()
      .email('Invalid email address')
      .required('Email is required!'),
    // password: yup.string().required('Password is required!'),
    package: yup.string().required('Package is required!'),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    setSubmitting(false);
    props.handleSubmit(values);
    resetForm();
  },
  displayName: 'editCenterForm', // helps with React DevTools
})(EditCenterForm);
