import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import swal from 'sweetalert';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { PackageForm } from '../../components/forms';

const addPackageMutation = gql`
  mutation addPackage($input: RegisterPackage) {
    addPackage(input: $input) {
      id
      name
      reports
    }
  }
`;

const AddPackage = () => {
  const [executeMutation, { data, loading, error }] =
    useMutation(addPackageMutation);

  const handleSubmit = (val) => {
    executeMutation({
      variables: {
        input: {
          ...val,
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Package can not be created', 'error');
    }
    if (data) {
      swal('Congrats', 'Package Added', 'success');
    }
  }, [data, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16  text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            <PackageForm handleSubmit={handleSubmit} loading={loading} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddPackage;
