import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';

import { InputGroup, Button } from '../elements';

const Container = styled.form``;

const PackageForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  loading,
}) => {
  return (
    <Container onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Name"
          name="name"
          type="text"
          placeholder="Package Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="mb-6">
        <InputGroup
          label="Reports"
          name="reports"
          type="number"
          placeholder="Add Reports Number"
          value={values.reports}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={
            errors.reports && touched.reports ? errors.reports : undefined
          }
        />
      </div>
      <Button
        type="submit"
        loading={isSubmitting || loading}
        className="mt-4 w-3/12 uppercase justify-center"
      >
        Update
      </Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    name: initialValues && initialValues.name ? initialValues.name : '',
    reports:
      initialValues && initialValues.reports ? initialValues.reports : '',
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('Name is required!'),
    reports: yup.number().required().positive().integer(),
  }),

  handleSubmit: (values, { resetForm, props }) => {
    props.handleSubmit(values);
    resetForm();
  },
  displayName: 'PackageForm', // helps with React DevTools
})(PackageForm);
