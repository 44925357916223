import React from 'react';

// const Container = styled.div``;

const Tag = ({ status }) => {
  return (
    <>
      {status === 'pendingAgencyApproval' && (
        <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-2 text-center py-1 bg-yellow-200 text-yellow-700 rounded-full">
          <h1 className="text-xs">Need Agency Approval</h1>
        </div>
      )}
      {status === 'draft' && (
        <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-yellow-200 text-yellow-700 rounded-full">
          {status}
        </div>
      )}

      {(status === 'active' || status === 'success') && (
        <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-green-200 text-green-700 rounded-full">
          {status}
        </div>
      )}

      {status === 'banned' && (
        <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 bg-red-200 text-orange-700 rounded-full">
          {status}
        </div>
      )}
    </>
  );
};

export default Tag;
