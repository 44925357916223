import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { GlobalStyle } from './theme';

import Index from '../pages/index';
import Dashboard from '../pages/dashboard';
import Profile from '../pages/profile';
import AddAgency from '../pages/agency/add';
import AllAgency from '../pages/agency/viewAll';
import AddCustomer from '../pages/customer/add';
import AllCustomer from '../pages/customer/viewAll';
import EditCustomer from '../pages/customer/edit';
import AddCenter from '../pages/center/add';
import AllCenter from '../pages/center/viewAll';
import EditCenter from '../pages/center/edit';
import AddPackage from '../pages/package/add';
import AllPackages from '../pages/package/View';
import EditPackage from '../pages/package/edit';
import Reports from '../pages/report/viewAll';
import MakeReport from '../pages/report/makeReport';
import PdfReport from '../pages/report/pdfReport';

// For Agecny
import CenterReports from '../pages/agency/reports/centerReports';

import Error404 from '../pages/404';

import Login from '../pages/Login';

const PublicRoute = ({ component: Component, access, ...rest }) => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const user = useStoreState((state) => state.user.data);

  return (
    <Route
      {...rest}
      render={(props) => {
        const redirectTo = '/dashboard';
        if (isLoggedIn) {
          return <Redirect to={redirectTo} />;
        }
        if (access !== user.type) {
          return <Redirect to="/not-allowed" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const PrivateRoute = ({ component: Component, access, ...rest }) => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);
  const user = useStoreState((state) => state.user.data);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        if (access && !access.includes(user.type)) {
          return <Redirect to="/not-allowed" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PublicRoute exact path="/" component={Index} />
          <PublicRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute
            exact
            path="/agency/all"
            component={AllAgency}
            access={['admin']}
          />
          <PrivateRoute
            exact
            path="/agency/add"
            component={AddAgency}
            access={['admin']}
          />
          <PrivateRoute
            access={['admin', 'center', 'agency']}
            exact
            path="/customer/all"
            component={AllCustomer}
          />
          <PrivateRoute
            access={['admin', 'center', 'agency']}
            exact
            path="/customer/add"
            component={AddCustomer}
          />
          <PrivateRoute
            access={['admin', 'center', 'agency']}
            exact
            path="/customer/:id"
            component={EditCustomer}
          />
          <PrivateRoute
            exact
            access={['admin']}
            path="/agency/:id/"
            component={EditCenter}
          />
          <PrivateRoute
            exact
            access={['admin', 'agency']}
            path="/center/all"
            component={AllCenter}
          />
          <PrivateRoute
            exact
            access={['admin', 'agency']}
            path="/center/add"
            component={AddCenter}
          />
          <PrivateRoute
            exact
            access={['admin', 'agency']}
            path="/center/:id"
            component={EditCenter}
          />

          <PrivateRoute
            exact
            path="/package/all"
            component={AllPackages}
            access={['admin']}
          />
          <PrivateRoute
            exact
            path="/package/add"
            component={AddPackage}
            access={['admin']}
          />
          <PrivateRoute
            exact
            path="/package/edit/:id"
            component={EditPackage}
            access={['admin']}
          />
          <PrivateRoute exact path="/reports/:status" component={Reports} />

          <PrivateRoute
            exact
            access={['agency']}
            path="/reports/center/:status"
            component={CenterReports}
          />

          <PrivateRoute
            access={['admin']}
            path="/report/make/:id"
            component={MakeReport}
          />
          <PrivateRoute path="/report/pdf/:id" component={PdfReport} />
          <Route component={Error404} />
        </Switch>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

export default Routes;
