import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import empty from '../../assets/empty.png';

const Container = styled.div``;

const EmptyState = ({ image, title, subTitle }) => (
  <Container className="flex flex-col items-center mt-24">
    <img src={image || empty} className="h-64" alt="empty-state" />
    {/* <span className="icon is-large has-text-warning is-size-2 ">{icon}</span> */}
    <h3 className="text-center text-3xl">{title}</h3>
    <h3 className="mt-1 text-center text-2xl">{subTitle}</h3>
  </Container>
);

EmptyState.defaultProps = {
  title: 'No data available',
};

EmptyState.propTypes = {
  title: PropTypes.string,
};

export default EmptyState;
