import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    lineHeight: 1.5,
  },
  subSection: {
    paddingHorizontal: 10,
  },
  space: {
    paddingVertical: 6,
  },
  textLineHeight: {
    paddingBottom: 1.75,
  },
});

const ReportData = ({ values }) => (
  <View
  // wrap={false}
  >
    {values &&
      values.length > 0 &&
      values.map((item) => {
        return (
          <View key={Math.random()} wrap={false}>
            {item.label && (
              <Text
                style={[
                  styles.textLineHeight,
                  {
                    fontSize: 14,
                    color: '#13274F',
                    fontWeight: 500,
                  },
                ]}
              >
                * {item.label}
              </Text>
            )}

            {item.details && (
              <Text
                style={[
                  styles.text,
                  {
                    marginTop: 5,
                    paddingHorizontal: 10,
                  },
                ]}
              >
                {item.details}
              </Text>
            )}
            {item.values &&
              item.values.map((child) => (
                <View
                  key={Math.random()}
                  style={styles.subSection}
                  // wrap={false}
                >
                  <Text style={[styles.text, { marginTop: 10 }]}>
                    * {child.label}
                  </Text>
                  {child.details && (
                    <Text
                      style={[
                        styles.text,
                        {
                          marginTop: 5,
                          paddingHorizontal: 10,
                        },
                      ]}
                    >
                      {child.details}
                    </Text>
                  )}
                </View>
              ))}
            <View style={styles.space} />
          </View>
        );
      })}
  </View>
);
export default ReportData;
