import React, { useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { TableHead } from '../elements';

const tableHeadData = [
  { name: 'S.NO.' },
  { name: 'Name' },
  { name: 'Reports' },
  { name: 'Options' },
];

const PackageTable = ({ data }) => {
  const [show, setShow] = useState(null);

  const groupedClass =
    'bg-gray-100 text-gray-900 block px-2 py-2 cursor-pointer  account-link hover:text-white rounded-md m-1 transition duration-500 ease-in-out transform text-base';

  return (
    <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
      <table className="w-full whitespace-nowrap">
        <TableHead data={tableHeadData} />
        <tbody className="w-full">
          {data.map((item, index) => (
            <tr
              className="h-20 leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100 text-base"
              key={item.id}
            >
              <td className="p-4">{index + 1}</td>
              <td className="p-4">{item.name || ''}</td>
              <td className="p-4">{item.reports || ''}</td>
              <td className="px-7 2xl:px-0">
                {show === index ? (
                  <button
                    type="button"
                    onClick={() => setShow(null)}
                    className="focus:outline-none text-4xl"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setShow(index)}
                    className="focus:outline-none text-4xl"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                )}
                {show === index && (
                  <div className="dropdown-content bg-white shadow  absolute ">
                    <Link
                      to={`/package/edit/${item.id}`}
                      className={groupedClass}
                    >
                      <p>Edit</p>
                    </Link>
                    <div className={groupedClass}>
                      <p>Delete</p>
                    </div>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default PackageTable;
