import { gql, useQuery } from '@apollo/client';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import PackageTable from '../../components/tables/Package';

const allPackages = gql`
  query {
    allPackages {
      id
      name
      reports
    }
  }
`;

const AllPackages = () => {
  const { data } = useQuery(allPackages, {
    fetchPolicy: 'cache-and-network',
  });

  const packages = data && data.allPackages ? data.allPackages : [];

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <h1 className="text-4xl text-center text-green-600	m-5">All Packages</h1>
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <PackageTable data={packages} />
      </div>
    </DashboardLayout>
  );
};

export default AllPackages;
