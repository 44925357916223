import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';
import { MdKeyboardArrowRight } from 'react-icons/md';

import SubMenu from './SubMenu';

const Navbar = styled.nav`
  background: ${(props) => props.theme.mainBrandColor};
`;

const Sidebar = ({ sidebarItems }) => {
  const [collapseShow, setCollapseShow] = useState('hidden');
  const location = useLocation();

  return (
    <Navbar className="p-1 md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-56 z-10">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        <div className="py-4 px-6 text-center ">
          <button
            className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <GiHamburgerMenu />
          </button>
          <Link
            to="/dashboard"
            className="text-white text-3xl font-semibold uppercase hover:text-gray-300 "
          >
            IRIS
            <span>Center</span>
          </Link>
        </div>
        <Navbar
          className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ${collapseShow}`}
        >
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link
                  to="/dashboard"
                  className="text-white text-3xl font-semibold uppercase hover:text-gray-300"
                >
                  Admin
                </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  onClick={() => setCollapseShow('hidden')}
                >
                  <ImCross />
                </button>
              </div>
            </div>
          </div>
          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            {sidebarItems.length > 0 &&
              sidebarItems.map((item) => {
                if (!item.hasSubMenu) {
                  return (
                    <li key={item.id}>
                      <Link
                        to={item.href}
                        className={`flex items-center text-white py-4 pl-6 nav-item transition delay-75 rounded-lg mb-1 ${
                          item.href === `${location.pathname}`
                            ? 'active-nav-link'
                            : ''
                        }`}
                      >
                        <span className="mr-3">{item.icon}</span>
                        {item.name}
                        {item.hasSubMenu && (
                          <span className="ml-3">
                            <MdKeyboardArrowRight />
                          </span>
                        )}
                      </Link>
                    </li>
                  );
                }
                return <SubMenu item={item} key={item.id} />;
              })}
          </ul>
        </Navbar>
      </div>
    </Navbar>
  );
};

export default Sidebar;
