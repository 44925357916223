const teamMembers = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
];

const Team = () => (
  <>
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-16">
          <h1 className="sm:text-5xl  text-6xl font-bold  text-green-600">
            Our Team
          </h1>
        </div>
        <div className="flex flex-wrap -m-2">
          {teamMembers.map((item) => (
            <div className="p-2 lg:w-1/3 md:w-1/2 w-full " key={item.id}>
              <div className="h-full flex items-center border-green-400 border p-4 rounded-lg">
                <img
                  alt="team"
                  className="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4"
                  src="https://dummyimage.com/80x80"
                />
                <div className="flex-grow">
                  <h2 className="text-gray-900 title-font font-medium">
                    Holden Caulfield
                  </h2>
                  <p className="text-gray-500">Doctor</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
);

export default Team;
