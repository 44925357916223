import React, { useState, useEffect } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { findIndex } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

const SubMenu = ({ item }) => {
  const [active, setActive] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const index = findIndex(item.subMenu, { href: currentPath });
    setActive(index !== -1);
  }, []);

  return (
    <li className="items-center">
      <div
        className={`cursor-pointer flex items-center text-white py-4 pl-6 nav-item  transition delay-75 rounded-lg mb-1 ${
          location.href === `${location.pathname}` ? 'active-nav-link' : ''
        }`}
        onClick={() => setActive(!active)}
        aria-hidden="true"
      >
        <span className="mr-3">{item.icon}</span>
        {item.name}
        {item.hasSubMenu && (
          <span className="ml-3 absolute right-5">
            {active ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />}
          </span>
        )}
      </div>
      {active &&
        item.subMenu.map((data, index) => {
          return (
            <ul
              className="md:flex-col md:min-w-full flex flex-col list-none"
              key={data.id}
            >
              <li>
                <Link
                  to={data.href}
                  className={`flex items-center text-white py-4 pl-6 nav-item transition delay-75 rounded-lg mb-1 ${
                    data.href === `${location.pathname}`
                      ? 'active-nav-link'
                      : ''
                  }`}
                >
                  {/* <span className="mr-3">{item.icon} </span> */}
                  <span className="ml-4">
                    {index + 1}.<span className="ml-1">{data.name}</span>
                  </span>
                </Link>
              </li>
            </ul>
          );
        })}
    </li>
  );
};

export default SubMenu;
