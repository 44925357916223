import { useState } from 'react';
import { remove } from 'lodash';

import { TextAreaGroup } from './elements';
import CheckBoxFields from './CheckBoxFeilds';
import reportFields from '../utils/reportFields';
import CustomerDetails from './CustomerDetails';

const fields = [
  {
    id: 1,
    label: 'Constitution',
    data: 'constitution',
  },
  {
    id: 2,
    label: 'DNA Dominance',
    data: 'dNADominance',
  },
  {
    id: 3,
    label: 'Constitutional Quality',
    data: 'constitutionalQuality',
  },
  {
    id: 4,
    label: 'Collarette Integrity',
    data: 'collaretteIntegrity',
  },
  {
    id: 5,
    label: 'Presence of Lacuna',
    data: 'presenceOfLacuna',
  },
  {
    id: 6,
    label: 'Types of Lacunae',
    data: 'typesOfLacunae',
  },
  {
    id: 7,
    label: 'Presence of Crypts',
    data: 'presenceOfCrypts',
  },
  {
    id: 8,
    label: 'Mammillations',
    data: 'mammillations',
  },
  {
    id: 9,
    label: 'Pupillary Borders (Inner And Outer)',
    data: 'pupillaryBordersInnerAndOuter',
  },
  {
    id: 10,
    label: 'Imbalanced Eliminated Organs',
    data: 'imbalancedEliminatedOrgans',
  },
  {
    id: 11,
    label: 'Radial Furrows',
    data: 'radialFurrows',
  },
  {
    id: 12,
    label: 'Contracted Furrows',
    data: 'contractedFurrows',
  },
  {
    id: 13,
    label: 'Pigmentation',
    data: 'pigmentation',
  },
  {
    id: 14,
    label: 'Miscellaneous Iris Sign',
    data: 'miscellaneousIrisSign',
  },
  {
    id: 15,
    label: 'Pupillary Dynamics',
    data: 'pupillaryDynamics',
  },
  {
    id: 16,
    label: 'Pupils Tonus',
    data: 'pupilsTonus',
  },
  {
    id: 17,
    label: 'Pupil Flatness',
    data: 'pupilFlatness',
  },
  {
    id: 18,
    label: 'Conjunctival and Corneal Sign',
    data: 'conjunctivalAndCornealSign',
  },
  {
    id: 19,
    label: 'Transversal',
    data: 'transversal',
  },
  {
    id: 20,
    label: 'Presence of Rings and Structures',
    data: 'presenceOfRingsAndStructures',
  },
  {
    id: 21,
    label: 'The Axis',
    data: 'theAxis',
  },
];

const ReportCheckBox = ({
  customer,
  initialValues,
  details,
  setDetails,
  handleSubmit,
}) => {
  const [values, setValue] = useState(initialValues);

  const handleOnClickValues = (val, hasCheckedIndex) => {
    // eslint-disable-next-line prefer-const
    let checkedValues = [...values];
    if (hasCheckedIndex > -1) {
      checkedValues = remove(checkedValues, (n) => {
        return n !== checkedValues[hasCheckedIndex];
      });
    } else {
      checkedValues.push(val);
    }
    setValue(checkedValues);
  };

  // useEffect(() => {
  //   if (!isEmpty(initialValues)) {
  //     setValue(initialValues);
  //   }
  // }, [initialValues]);

  return (
    <>
      <div className="pl-12  flex flex-row">
        <div className="w-5/12">
          <CustomerDetails customer={customer} />
        </div>
        <div className="w-7/12">
          {fields.map((item) => (
            <CheckBoxFields
              key={item.id}
              title={item.label}
              data={reportFields[`${item.data}`]}
              initialValues={values}
              handleOnClick={handleOnClickValues}
            />
          ))}
          <TextAreaGroup
            label="Other Information"
            value={details}
            name="name"
            type="text"
            placeholder="Other Information"
            onChange={(e) => setDetails(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-8 flex items-center justify-center">
        <button
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded h-18 w-56"
          type="button"
          onClick={() => handleSubmit(values)}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default ReportCheckBox;
