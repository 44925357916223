import { Page, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import Header from './Header';
import Details from './Details';
import Footer from './Footer';

import NormalFont from '../../assets/fonts/Roboto-Regular.ttf';
import MediumFont from '../../assets/fonts/Roboto-Medium.ttf';
import BoldFont from '../../assets/fonts/Roboto-Bold.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: NormalFont }, // font-style: normal, font-weight: normal
    { src: MediumFont, fontWeight: 500 },
    { src: BoldFont, fontWeight: 900 },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    paddingBottom: 50,
  },
  container: {
    backgroundColor: '#fff',
    paddingTop: 10,
    flexDirection: 'column',
    paddingHorizontal: 65,
    margin: 2,
  },
  space: {
    // backgroundColor: '#0a6f4d',
    height: 160,
  },
  top: {
    margin: 5,
    // padding: 10,
    alignItems: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const PdfPage = ({ report, data }) => {
  return (
    <Page size="A4" wrap style={styles.page}>
      <View style={styles.space} fixed />
      <View style={styles.container}>
        <Header customer={report.customer} reportDate={report?.createdAt} />
        <View style={styles.top}>
          <Text
            style={{
              fontWeight: 900,
              color: '#0a6f4d',
              textDecoration: 'underline',
            }}
          >
            IMPRESSIONS
          </Text>
        </View>
        <Details report={report} data={data} />
        <Footer />
      </View>
    </Page>
  );
};

export default PdfPage;
