import React, { Component } from 'react';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

const CustomerEyes = ({ customer }) => {
  return (
    <div className="fixed left-2 top-8  z-50">
      <div className="flex">
        <div>
          <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-5 py-1 bg-red-200 text-red-700 rounded-md">
            LEFT EYE
          </div>
          <div className="h-56 w-80">
            <TransformWrapper initialScale={1}>
              <TransformComponent>
                <img
                  className="rounded shadow-md"
                  src={customer.images.leftEye}
                  alt="leftEye"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>

        <div>
          <div className="text-xs inline-flex items-center font-bold leading-sm uppercase px-5 py-1 bg-red-200 text-red-700 rounded-md">
            RIGHT EYE
          </div>
          <div className="h-56 w-80">
            <TransformWrapper initialScale={1}>
              <TransformComponent>
                <img
                  className="rounded shadow-md"
                  src={customer.images.rightEye}
                  alt="rightEye"
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerEyes;
