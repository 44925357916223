import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import { useStoreState } from 'easy-peasy';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { CustomerTable } from '../../components/tables';
import { EmptyState, Loading } from '../../components/elements';

const allCustomerQuery = gql`
  query allCustomer {
    allCustomer {
      id
      customerId
      createdBy {
        id
        name
        userId
      }
      name
      telephone
      email
      address
      createdAt
    }
  }
`;

const createReportMutation = gql`
  mutation createReport($input: ReportInput) {
    createReport(input: $input) {
      id
    }
  }
`;

const AllCustomer = () => {
  const user = useStoreState((state) => state.user.data);

  const { data, loading } = useQuery(allCustomerQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { loading: loading1 }] =
    useMutation(createReportMutation);

  const allCustomer = data && data.allCustomer ? data.allCustomer : [];

  const handleCreateReport = async (val) => {
    const inputObj = {
      customer: val.id,
      initiatedBy: val.createdBy.id,
      status: 'pending',
    };

    if (
      user.createdBy &&
      user.createdBy.type &&
      user.createdBy.type === 'agency'
    ) {
      inputObj.agency = user.createdBy.id;
    } else {
      inputObj.agency = user.id;
      inputObj.hasAgencyApproval = true;
      inputObj.hasAgencySendApproval = true;
    }

    const resp = await executeMutation({
      variables: {
        input: inputObj,
      },
    });
    if (resp && resp.data) {
      swal('Report send to admin', ' ', 'success');
    }
  };

  return (
    <DashboardLayout>
      <Seo title="All Customer" description="Iris Center All Customer" />

      <h1 className="text-4xl text-center text-green-600 border-b-2 pb-2	m-5">
        All Customer
      </h1>
      {isEmpty(allCustomer) && <EmptyState subTitle="Please add a Customer" />}
      {(loading || loading1) && <Loading />}

      {!isEmpty(allCustomer) && (
        <div className="w-full pb-4 md:px-0 md-8 mb-16 b text-gray-800 leading-normal">
          <CustomerTable
            data={allCustomer}
            handleCreateReport={handleCreateReport}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default AllCustomer;
