export default {
  debug: process.env.NODE_ENV === 'development',
  siteName: 'IRIS Center',
  projectKey: 'IRIS-Center',
  graphQlUri:
    process.env.REACT_APP_WEB_URL ||
    'https://iris-center-api.vercel.app/api/graphql',
  graphQlUriDev: 'https://iris-center-api.vercel.app/api/graphql',
  // graphQlUriDev: 'http://localhost:4000/api/graphql',
  sanityId: 'weh59w2m',
  sanityToken:
    'skbwZ5x6OkfEdo1WV6qCBooptn2HFGDczbarJ0fs3caxhtEtVlGfkFlfsDDAm7SHJwPViAv28fPrmqICTSRxMSJXpXOhhuPrqetv5OfluAEUPg2iaOlUhdj7s9hZRsA6kxVpUGfXf1HLjmxiz1vCY3xNm1mBgaYHYK7xRQFpB6aZgZnjoCeZ',
  email: 'no-reply@site.com',
  telephone: '+1123123123',
  address: 'London, UK',
};
