const AboutUs = () => {
  return (
    <section className="body-font mx-16">
      <div className="flex items-center justify-center  flex-col md:flex-row-reverse">
        <div className="flex items-center justify-center w-full md:w-6/12">
          <img
            className="w-8/12"
            src="https://boozmanhof.com/wp-content/uploads/2018/08/Diagram-of-the-Eye.png"
            alt="Iridology"
          />
        </div>
        <div className="w-full md:w-6/12 mt-16 sm:mt-0 px-4 md:px-0">
          <div className="text-6xl font-bold   text-green-600">About Us</div>
          <p className="text-2xl font-light mt-8">
            We are based in Punjab in district Nawanshar and are an authorized
            Iris Analysis Centre of Kings Herbal Research Laboratories OUR
            Estonia (Europe) to conduct the Iris Analysis check-up for the
            peoples to make them understand about their health in iridology
            point of view.
          </p>
          <p className="text-2xl font-light mt-6">
            The process of making report is online but patient has to
            visit/attend once in person to click the picture of his/her Iris of
            eye with the help of Iridoscope and then forwarded to the experts of
            iridology working in association of the Dr Ajit Singh who is the
            head of KHRLOU.
          </p>
          {/* <p className="text-2xl font-light mt-6">
            Following is the KHRL team and other senior iridologist (worldwide)
            who are consulted by KHRL team for making the Iris Analysis Report.
          </p> */}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
