import React, { useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import dayjs from 'dayjs';

import { TableHead } from '../elements';

const tableHeadData = [
  { name: 'S.NO.', width: 'w-1' },
  { name: 'Customer ID', width: 'w-1/5' },
  { name: 'Name' },
  { name: 'Phone NO.' },
  { name: 'Created By', isVisible: 'admin' },
  { name: 'Email-ID', isVisible: 'center' },
  { name: 'Location', width: 'w-3/5', isVisible: 'center' },
  { name: 'Date', width: 'w-1/5' },
  { name: 'More' },
];

const Tr = styled.tr``;

const CustomerTable = ({ data, handleCreateReport }) => {
  const user = useStoreState((state) => state.user.data);

  const [show, setShow] = useState(null);

  const groupedClass =
    'bg-gray-100 text-gray-900 block px-4 py-2 cursor-pointer text-xs account-link hover:text-white rounded-md m-1 transition duration-500 ease-in-out transform';

  return (
    <div className="shadow-xl	 px-1 md:px-1 pt-4 md:pt-7 pb-5 overflow-y-auto">
      <table className="w-full border-collapse border  ">
        <TableHead data={tableHeadData} />
        <tbody className="w-full">
          {data.map((item, index) => {
            return (
              <Tr
                className="h-20 text-sm leading-4	 text-gray-800 bg-white hover:bg-gray-100 	divide-x divide-gray-100 border-2"
                key={item.id}
              >
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{item.customerId || ''}</td>
                <td className="p-2">{item.name || ''}</td>
                <td className="p-2">{item.telephone || ''}</td>
                {user.type === 'admin' && (
                  <td className="p-2">
                    {(item.createdBy && item.createdBy.name) || ''}
                  </td>
                )}
                {user.type === 'center' && (
                  <>
                    <td className="p-2">{item.email || ''}</td>
                    <td className="p-2 ">
                      {item.address && `${item.address.substring(0, 24)}...`}
                    </td>
                  </>
                )}
                <td className="p-2">
                  {dayjs(item.createdAt).format('DD/MM/YYYY')}
                </td>

                {/* <td className="p-2">
                <Tag status="active" />
              </td> */}
                <td className="p-2 2xl:px-0">
                  {show === index ? (
                    <button
                      type="button"
                      onClick={() => setShow(null)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setShow(index)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  )}
                  {show === index && (
                    <div className="dropdown-content bg-white shadow w-36 absolute z-30 right-0 mr-6">
                      <Link
                        to={`/customer/${item.id}`}
                        className={groupedClass}
                      >
                        <p>Edit</p>
                      </Link>
                      {/* <div className={groupedClass}>
                      <p>Achieve</p>
                    </div> */}
                      <div
                        className={groupedClass}
                        onClick={() => handleCreateReport(item)}
                      >
                        <p>Generate Report</p>
                      </div>
                    </div>
                  )}
                </td>
              </Tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default CustomerTable;
