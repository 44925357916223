import React from 'react';

const TextAreaGroup = ({ label, errors, children, ...otherProps }) => (
  <>
    {label && (
      <label className="block uppercase tracking-wide text-gray-700 text-base font-bold mt-6 mb-4">
        {label}
      </label>
    )}
    {children || (
      <textarea
        className={`appearance-none block w-full mt-2  text-gray-700 border  rounded py-8 px-4 mb-3 leading-tight focus:outline-none focus:bg-white ${
          errors ? 'border-red-500' : 'border-gray-500'
        }  `}
        {...otherProps}
      />
    )}

    {errors && <p className="text-red-500 text-xs italic">{errors}</p>}
  </>
);

export default TextAreaGroup;
