import { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import swal from 'sweetalert';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { CustomerForm } from '../../components/forms';
import { Loading } from '../../components/elements';

const addCustomerMutation = gql`
  mutation addCustomer($input: CustomerInput) {
    addCustomer(input: $input) {
      id
      customerId
    }
  }
`;

const AddCustomer = ({ history }) => {
  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(addCustomerMutation);

  const handleSubmit = (formData) => {
    const obj = {
      ...formData,
    };
    delete obj.leftImage;
    delete obj.rightImage;

    executeMutation({
      variables: {
        input: obj,
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Customer can not be created', 'error');
    }
    if (data1) {
      swal('Congrats', 'Customer Created', 'success');
      history.push(`/customer/all`);
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {loading1 && <Loading />}
            <CustomerForm handleSubmit={handleSubmit} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCustomer;
