import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { findIndex, remove } from 'lodash';

import {
  InputGroup,
  Button,
  CheckBox,
  ImageUpload,
  RadioInput,
  TextAreaGroup,
} from '../elements';

const Container = styled.form``;

const CustomerForm = ({
  values,
  touched,
  errors,
  isSubmitting,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const problems = [
    {
      id: 1,
      value: 'Piles',
    },
    {
      id: 2,
      value: 'Polyps(Nasal)',
    },
    {
      id: 3,
      value: 'Fits(Brain)',
    },
    {
      id: 4,
      value: 'Skin',
    },
    {
      id: 5,
      value: 'Sinusitis',
    },
    {
      id: 6,
      value: 'Hyper',
    },
    {
      id: 7,
      value: 'PoorDefecation',
    },
    {
      id: 8,
      value: 'Headache',
    },
    {
      id: 9,
      value: 'Sleep',
    },
  ];

  const handleCheckedValues = (val, hasCheckedIndex) => {
    // eslint-disable-next-line prefer-const
    let checkedValues = [...values.specificProblems];
    if (hasCheckedIndex > -1) {
      checkedValues = remove(checkedValues, (n) => {
        return n !== checkedValues[hasCheckedIndex];
      });
    } else {
      checkedValues.push(val);
    }
    setFieldValue('specificProblems', checkedValues);
  };

  return (
    <Container onSubmit={handleSubmit}>
      <div className="mb-4">
        <InputGroup
          label="Customer Name:"
          name="name"
          type="text"
          placeholder="Customer Name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          errors={errors.name && touched.name ? errors.name : undefined}
        />
      </div>
      <div className="mt-4">
        <label className="block uppercase tracking-wide  text-gray-700 text-base font-bold mt-6 mb-4">
          Gender
        </label>
        <div className="mt-2 flex ">
          <RadioInput
            name="gender"
            value="male"
            label="Male"
            hasChecked={values.gender === 'male'}
            onClick={(val) => setFieldValue('gender', val)}
          />
          <span className="ml-4" />
          <RadioInput
            name="gender"
            value="female"
            label="Female"
            hasChecked={values.gender === 'female'}
            onClick={(val) => setFieldValue('gender', val)}
          />
        </div>
      </div>

      <div className="mt-8">
        <InputGroup
          label="Customer Age:"
          name="age"
          type="number"
          placeholder="Age"
          value={values.age}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="mt-8 mb-8">
        <InputGroup
          label="Address"
          name="address"
          type="text"
          placeholder="Address"
          value={values.address}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="mb-8">
        <InputGroup
          label="Customer Email"
          name="email"
          type="text"
          placeholder="Enter Customer Email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="mb-8">
        <InputGroup
          label="Phone No."
          name="telephone"
          placeholder="Phone No."
          type="text"
          value={values.telephone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className="mb-8">
          <InputGroup
            label="Any History of diseases in family ?"
            name="familyHistory"
            placeholder="Any History of diseases in family"
            type="text"
            value={values.familyHistory}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-8">
          <InputGroup
            label="Present Any Symptoms & sufferings"
            name="symptomsAndSufferings"
            placeholder="Symptoms & sufferings"
            type="text"
            value={values.symptomsAndSufferings}
            onChange={handleChange}
            onBlur={handleBlur}
            errors={
              errors.Symptoms && touched.Symptoms ? errors.Symptoms : undefined
            }
          />
        </div>
        <div className="mb-8">
          <InputGroup
            label="History of any treatment taken for long & Diseases"
            name="treatmentHistory"
            type="text"
            placeholder="History of any treatment taken for long & Diseases"
            value={values.treatmentHistory}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-8">
          <InputGroup
            label="Any history of surgical operations"
            name="surgicalOperationsHistory"
            type="text"
            placeholder="Any history of surgical operations"
            value={values.surgicalOperationsHistory}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-8">
          <InputGroup
            label="History of genetic problems ? ( in females )"
            name="gynecologyProblems"
            type="text"
            placeholder="History of genetic problems ? ( in females )"
            value={values.gynecologyProblems}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div className="mb-8">
          <InputGroup
            label="History of Reproductive Problems ? ( in Males )"
            name="reproductiveProblems"
            type="text"
            placeholder="History of Reproductive Problems ? ( in Males )"
            value={values.reproductiveProblems}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>

        <div className="mb-8">
          <p className="font-bold uppercase mb-4">Any specific Problem</p>
          {problems.map((item) => {
            const hasChecked = findIndex(values.specificProblems, (o) => {
              return o === item.value;
            });

            return (
              <CheckBox
                key={item.id}
                label={item.value}
                data={item.value}
                hasChecked={hasChecked > -1}
                onClick={(val) => handleCheckedValues(val, hasChecked)}
              />
            );
          })}
        </div>
        <div className="mb-8">
          <TextAreaGroup
            label="Any Other Information"
            name="details"
            type="text"
            placeholder="Any Other Information"
            value={values.details}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      </div>

      <div className="flex md:flex-row justify-between mb-16">
        <ImageUpload
          value={values.leftImage}
          title="Upload Left Eye Image"
          hasButton
          buttonTitle="Select Image"
          onSuccess={(val) => setFieldValue('leftImage', val)}
        />

        <ImageUpload
          title="Upload Right Eye Image"
          value={values.rightImage}
          hasButton
          buttonTitle="Select Image"
          onSuccess={(val) => setFieldValue('rightImage', val)}
        />
      </div>

      <Button
        type="submit"
        loading={isSubmitting}
        className="mt-4 w-3/12 uppercase justify-center"
      >
        Submit
      </Button>
    </Container>
  );
};

export default withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    name: initialValues && initialValues.name ? initialValues.name : [],
    age: initialValues && initialValues.age ? initialValues.age : '',
    address:
      initialValues && initialValues.address ? initialValues.address : '',
    gender: initialValues && initialValues.gender ? initialValues.gender : '',
    email: initialValues && initialValues.email ? initialValues.email : '',
    telephone:
      initialValues && initialValues.telephone ? initialValues.telephone : '',
    familyHistory:
      initialValues && initialValues.familyHistory
        ? initialValues.familyHistory
        : '',
    symptomsAndSufferings:
      initialValues && initialValues.symptomsAndSufferings
        ? initialValues.symptomsAndSufferings
        : '',
    treatmentHistory:
      initialValues && initialValues.treatmentHistory
        ? initialValues.treatmentHistory
        : '',
    surgicalOperationsHistory:
      initialValues && initialValues.surgicalOperationsHistory
        ? initialValues.surgicalOperationsHistory
        : '',
    gynecologyProblems:
      initialValues && initialValues.gynecologyProblems
        ? initialValues.gynecologyProblems
        : '',
    reproductiveProblems:
      initialValues && initialValues.reproductiveProblems
        ? initialValues.reproductiveProblems
        : '',
    details:
      initialValues && initialValues.details ? initialValues.details : '',
    specificProblems:
      initialValues && initialValues.specificProblems
        ? initialValues.specificProblems
        : [],
    leftImage:
      initialValues && initialValues.images && initialValues.images.leftEye
        ? initialValues.images.leftEye
        : '',
    rightImage:
      initialValues && initialValues.images && initialValues.images.rightEye
        ? initialValues.images.rightEye
        : '',
  }),
  validationSchema: yup.object().shape({
    name: yup.string().required('customer Name is required!'),
  }),

  handleSubmit: (values, { setSubmitting, resetForm, props }) => {
    setSubmitting(false);

    props.handleSubmit({
      ...values,
      images: {
        leftEye: values.leftImage,
        rightEye: values.rightImage,
      },
    });
    resetForm();
  },
  displayName: 'AddCustomerForm', // helps with React DevTools
})(CustomerForm);
