const ProfileCard = ({ user }) => (
  <div className="flex items-center justify-center">
    <div className="bg-white w-full rounded-lg shadow-xl pb-6">
      <div className="p-4 border-b">
        <h2 className="text-2xl ">Personal Information</h2>
      </div>
      <div>
        <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 p-4 ">
          <p className="text-gray-600">Full name</p>
          <p>{user.name}</p>
        </div>
        <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 p-4 ">
          <p className="text-gray-600">Email Address</p>
          <p>{user.email}</p>
        </div>
        <div className="md:grid md:grid-cols-2 hover:bg-gray-50 md:space-y-0 space-y-1 p-4 ">
          <p className="text-gray-600">Role</p>
          <p className="uppercase">{user.type}</p>
        </div>
      </div>
      {/* <div className="mt-8 flex justify-center">
        <a href="/profile">
          <a className="border-2	bg-transparent hover:bg-green-500	 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded content-center">
            Update
          </a>
        </a>
      </div> */}
    </div>
  </div>
);

export default ProfileCard;
