import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  input[type='checkbox'] + label span {
    transition: background 0.05s, transform 0.05s;
  }

  input[type='checkbox'] + label span:hover,
  input[type='checkbox'] + label:hover span {
    transform: scale(1.2);
  }

  input[type='checkbox']:checked + label span {
    background-color: #3490dc; //bg-blue
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type='checkbox']:checked + label {
    color: #3490dc; //text-blue
  }
`;

const CheckBox = ({ label, data, onClick, hasChecked = false }) => {
  const [checked, setChecked] = useState(hasChecked);
  return (
    <Container className="flex items-center mb-2">
      <input
        type="checkbox"
        name={label}
        className="hidden"
        checked={checked}
        onChange={() => {
          setChecked(!checked);
          if (data) {
            onClick(data);
          }
        }}
      />

      <label
        htmlFor="checkbox"
        className="flex  cursor-pointer text-lg"
        onClick={() => {
          setChecked(!checked);
          if (data) {
            onClick(data);
          }
        }}
        aria-hidden="true"
      >
        <span className="w-6 h-6 inline-block mr-2 rounded-full border border-black " />
        {label}
      </label>
    </Container>
  );
};

export default CheckBox;
