import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import ReportData from './ReportData';

const styles = StyleSheet.create({
  detailContainer: {
    marginTop: 20,
  },
  title: {
    color: '#2876C4',
    fontSize: 16,
    fontWeight: 900,
    textDecorationLine: 'underline',
    borderBottomWidth: 1,
    paddingBottom: 5,
    borderColor: '#0a6f4d',
  },
  dataContainer: {
    marginTop: 20,
    paddingHorizontal: 10,
  },
});

const DetailItem = ({ data }) => {
  return (
    <View style={styles.detailContainer}>
      <View>
        <Text style={styles.title}>{data.label}</Text>
      </View>
      {data && data.values && (
        <View style={styles.dataContainer}>
          <ReportData values={data.values} />
        </View>
      )}
      {data.subTitle && (
        <View
          style={{ marginBottom: 10, paddingTop: 10, paddingHorizontal: 10 }}
          // wrap={false}
        >
          <Text
            style={{
              fontSize: 14,
              color: '#13274F',
              fontWeight: 500,
              lineHeight: 1.5,
            }}
          >
            {data.subTitle}
          </Text>
        </View>
      )}
    </View>
  );
};

export default DetailItem;
