import { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import ReportCheckBox from '../../components/ReportCheckBox';
import CustomerEyes from '../../components/CustomerEyes';
import { Loading } from '../../components/elements';

const singleReportQuery = gql`
  query singleReport($id: ID!) {
    singleReport(id: $id) {
      id
      fields
      details
      customer {
        id
        customerId
        address
        age
        details
        email
        familyHistory
        gender
        gynecologyProblems
        name
        telephone
        reproductiveProblems
        specificProblems
        surgicalOperationsHistory
        symptomsAndSufferings
        treatmentHistory
        images {
          leftEye
          rightEye
        }
      }
    }
  }
`;

const EditReportMutation = gql`
  mutation editReport($id: ID!, $input: ReportInput) {
    editReport(id: $id, input: $input) {
      id
    }
  }
`;

const MakeReport = ({ match, history }) => {
  const { id } = match.params;
  const [details, setDetails] = useState('');

  const { data, loading } = useQuery(singleReportQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(EditReportMutation);

  const report = data && data.singleReport ? data.singleReport : {};

  const customer = report && report.customer ? report.customer : {};

  const handleSubmit = async (formData) => {
    const obj = {
      customer: customer.id,
      fields: formData,
      status: 'draft',
      details,
    };

    await executeMutation({
      variables: {
        id,
        input: obj,
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Report can not be Created', 'error');
    }
    if (data1) {
      swal('Congrats', 'Report Created', 'success');
      history.push(`/reports/pending`);
    }
  }, [data1, error]);

  useEffect(() => {
    setDetails(report.details);
  }, [report]);

  // console.log('customer', customer);

  return (
    <>
      {!loading && customer && customer.images && (
        <CustomerEyes customer={customer} />
      )}

      <DashboardLayout>
        {(loading || loading1) && <Loading />}
        {!loading && (
          <>
            <ReportCheckBox
              customer={customer}
              initialValues={report.fields}
              details={details}
              setDetails={setDetails}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </DashboardLayout>
    </>
  );
};

export default MakeReport;
