import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const styles = StyleSheet.create({
  top: {
    flexDirection: 'column',
    marginBottom: 10,
    padding: 10,
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 5,
    paddingHorizontal: 5,
    borderWidth: 0.75,
    alignItems: 'center',
    marginBottom: 10,
  },
  info: {
    flexDirection: 'column',
  },
  infoContainer: {
    flexDirection: 'row',
  },
  text: {
    marginTop: 5,
    fontSize: 14,
  },
  images: {
    flexDirection: 'row',
  },
});

const Header = ({ customer, reportDate }) => (
  <View>
    <View style={styles.top}>
      <Text
        style={{
          fontWeight: 900,
          color: '#0a6f4d',
          textDecoration: 'underline',
        }}
      >
        IRIS ANALYSIS SHEET
      </Text>
    </View>
    <View style={styles.container}>
      {/* customer Info */}
      <View style={styles.info}>
        <View style={styles.infoContainer}>
          <Text style={[styles.text, { fontWeight: 500 }]}>Analysis Date:</Text>
          <Text
            style={[
              styles.text,
              { marginLeft: 10, textTransform: 'uppercase' },
            ]}
          >
            {dayjs(reportDate).format('DD/MM/YYYY')}
          </Text>
        </View>
        <View style={styles.infoContainer}>
          <Text style={[styles.text, { fontWeight: 500 }]}>Customer ID:</Text>
          <Text style={[styles.text, { marginLeft: 10 }]}>
            {customer.customerId}
          </Text>
        </View>
        <View style={styles.infoContainer}>
          <Text style={[styles.text, { fontWeight: 500 }]}>Customer Name:</Text>
          <Text style={[styles.text, { marginLeft: 10 }]}>{customer.name}</Text>
        </View>
        <View style={styles.infoContainer}>
          <Text style={[styles.text, { fontWeight: 500 }]}>Gender:</Text>
          <Text
            style={[
              styles.text,
              { marginLeft: 10, textTransform: 'uppercase' },
            ]}
          >
            {customer?.gender}
          </Text>
        </View>
      </View>
      {/* customer Info */}
      <View style={styles.images}>
        <Image
          src={customer.images.leftEye}
          style={{
            width: 80,
            height: 80,
          }}
        />
        <Image
          src={customer.images.rightEye}
          style={{
            marginLeft: 10,
            width: 80,
            height: 80,
          }}
        />
      </View>
    </View>
  </View>
);

export default Header;
