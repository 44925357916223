import { gql, useQuery } from '@apollo/client';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { EditCenterForm } from '../../components/forms';

const allPackages = gql`
  query {
    allPackages {
      id
      name
      reports
    }
  }
`;

const EditCenter = () => {
  const { data } = useQuery(allPackages, {
    fetchPolicy: 'cache-and-network',
  });

  const packages = data && data.allPackages ? data.allPackages : [];

  return (
    <DashboardLayout>
      <Seo title="Edit Agency" description="Iris Edit  Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16  text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            <EditCenterForm packages={packages} />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditCenter;
