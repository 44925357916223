import React, { useState, useEffect } from 'react';
import { Document, PDFViewer } from '@react-pdf/renderer';
import { gql, useQuery } from '@apollo/client';
import { findIndex, isEmpty } from 'lodash';

import PdfPage from '../../components/pdf/PdfPage';
import { Loading } from '../../components/elements';

import reportFields from '../../utils/reportFields';

const fields = [
  {
    id: 1,
    label: 'Constitution',
    data: 'constitution',
  },
  {
    id: 2,
    label: 'DNA Dominance',
    data: 'dNADominance',
  },
  {
    id: 3,
    label: 'Constitutional Quality',
    data: 'constitutionalQuality',
  },
  {
    id: 4,
    label: 'Collarette Integrity',
    data: 'collaretteIntegrity',
  },
  {
    id: 5,
    label: 'Presence of Lacuna',
    data: 'presenceOfLacuna',
  },
  {
    id: 6,
    label: 'Types of Lacunae',
    data: 'typesOfLacunae',
  },
  {
    id: 7,
    label: 'Presence of Crypts',
    data: 'presenceOfCrypts',
  },
  {
    id: 8,
    label: 'Mammillations',
    data: 'mammillations',
  },
  {
    id: 9,
    label: 'Pupillary Borders (Inner And Outer)',
    data: 'pupillaryBordersInnerAndOuter',
  },
  {
    id: 10,
    label: 'Imbalanced Eliminated Organs',
    data: 'imbalancedEliminatedOrgans',
  },
  {
    id: 11,
    label: 'Radial Furrows',
    data: 'radialFurrows',
  },
  {
    id: 12,
    label: 'Contracted Furrows',
    data: 'contractedFurrows',
  },
  {
    id: 13,
    label: 'Pigmentation',
    data: 'pigmentation',
  },
  {
    id: 14,
    label: 'Miscellaneous Iris Sign',
    data: 'miscellaneousIrisSign',
  },
  {
    id: 15,
    label: 'Pupillary Dynamics',
    data: 'pupillaryDynamics',
  },
  {
    id: 16,
    label: 'Pupils Tonus',
    data: 'pupilsTonus',
  },
  {
    id: 17,
    label: 'Pupil Flatness',
    data: 'pupilFlatness',
  },
  {
    id: 18,
    label: 'Conjunctival and Corneal Sign',
    data: 'conjunctivalAndCornealSign',
  },
  {
    id: 19,
    label: 'Transversal',
    data: 'transversal',
  },
  {
    id: 20,
    label: 'Presence of Rings and Structures',
    data: 'presenceOfRingsAndStructures',
  },
  {
    id: 21,
    label: 'The Axis',
    data: 'theAxis',
  },
];

const singleReportQuery = gql`
  query singleReport($id: ID!) {
    singleReport(id: $id) {
      id
      fields
      details
      customer {
        id
        customerId
        name
        gender
        images {
          leftEye
          rightEye
        }
      }
      createdAt
    }
  }
`;

// Create Document Component
const PdfReport = ({ match }) => {
  const { id } = match.params;
  const [reportData, setReportData] = useState([]);

  const { data, loading } = useQuery(singleReportQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  const report = data && data.singleReport ? data.singleReport : {};

  useEffect(() => {
    if (data && data.singleReport && data.singleReport.fields) {
      const filteredData = fields.map((item) => {
        const mainObj = {};
        mainObj.id = item.id;
        mainObj.label = item.label;
        // Child Values
        mainObj.values = [];
        reportFields[`${item.data}`].forEach((child) => {
          const childObj = {};
          // Check for Child values
          const hasCheckedChild = findIndex(data.singleReport.fields, (o) => {
            return o === child.id;
          });

          if (hasCheckedChild > -1) {
            childObj.id = child.id;
            childObj.label = child.label;
            if (child.details) {
              childObj.details = child.details;
            }
            childObj.values = [];
            if (child.values) {
              // Sub Child Values
              child.values.forEach((subChild) => {
                const subChildObj = {};
                // Check for sub Child values
                const hasCheckedSubChild = findIndex(report.fields, (o) => {
                  return o === subChild.id;
                });
                if (hasCheckedSubChild > -1) {
                  subChildObj.id = subChild.id;
                  subChildObj.label = subChild.label;
                  if (subChild.details) {
                    childObj.details = subChild.details;
                  }
                  childObj.values.push(subChildObj);
                }
              });
            }
            mainObj.values.push(childObj);
          }
        });
        return mainObj;
      });

      setReportData(filteredData);
    }
    return () => {
      setReportData([]);
    };
  }, [data]);

  if (loading || isEmpty(reportData)) {
    return <Loading />;
  }
  if (!isEmpty(reportData)) {
    return (
      <PDFViewer
        style={{
          width: '100%',
          height: '100vh',
        }}
      >
        <Document title={report.customer.customerId}>
          <PdfPage report={report} data={reportData} />
        </Document>
      </PDFViewer>
    );
  }
  return null;
};

export default PdfReport;
