import { useState } from 'react';
import { findIndex, isEmpty } from 'lodash';
import { CheckBox } from './elements';

const CheckBoxWrapper = ({ data, initialValues, handleOnClick }) => {
  const [active, setActive] = useState();

  const hasChecked = findIndex(initialValues, (o) => {
    return o === data.id;
  });

  return (
    <>
      <CheckBox
        label={data.label}
        key={data.id}
        hasChecked={hasChecked > -1}
        onClick={(val) => {
          handleOnClick(val, hasChecked);
          if (!isEmpty(data.values)) {
            setActive(!active);
          }
        }}
        data={data.id}
      />

      {data.values && (
        <div className="pl-12">
          {data.values.map((subChild) => {
            const hasCheckedSubChild = findIndex(initialValues, (o) => {
              return o === subChild.id;
            });
            if (active || hasCheckedSubChild > -1 || hasChecked > -1) {
              return (
                <CheckBox
                  label={subChild.label}
                  hasChecked={hasCheckedSubChild > -1}
                  key={subChild.id}
                  data={subChild.id}
                  onClick={(val) => handleOnClick(val, hasCheckedSubChild)}
                />
              );
            }
            return null;
          })}
        </div>
      )}
    </>
  );
};

const CheckBoxFields = ({ title, data, initialValues, handleOnClick }) => {
  return (
    <div className="mb-8">
      <h2 className="mb-4 text-2xl font-bold">{title}</h2>
      <div className="pl-16 mt-1">
        {data &&
          data.map((child) => {
            return (
              <CheckBoxWrapper
                key={child.id}
                data={child}
                initialValues={initialValues}
                handleOnClick={handleOnClick}
              />
            );
          })}
      </div>
    </div>
  );
};

export default CheckBoxFields;
