import { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';

import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { CustomerForm } from '../../components/forms';
import { Loading } from '../../components/elements';

const singleCustomerQuery = gql`
  query singleCustomer($id: ID!) {
    singleCustomer(id: $id) {
      address
      age
      details
      email
      familyHistory
      gender
      gynecologyProblems
      name
      telephone
      reproductiveProblems
      specificProblems
      surgicalOperationsHistory
      symptomsAndSufferings
      treatmentHistory
      images {
        leftEye
        rightEye
      }
    }
  }
`;

const EditCustomerMutation = gql`
  mutation editCustomer($id: ID!, $input: CustomerInput) {
    editCustomer(id: $id, input: $input) {
      id
    }
  }
`;

const EditCustomer = ({ match }) => {
  const { id } = match.params;

  const { data, loading, refetch } = useQuery(singleCustomerQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(EditCustomerMutation);

  const customerData = data && data.singleCustomer ? data.singleCustomer : {};

  const handleSubmit = (formData) => {
    const obj = {
      ...formData,
    };

    delete obj.leftImage;
    delete obj.rightImage;
    executeMutation({
      variables: {
        id,
        input: obj,
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Customer can not be updated', 'error');
    }
    if (data1) {
      refetch();
      swal('Congrats', 'Customer updated', 'success');
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Customer" description="Iris Center Add Customer" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {(loading || loading1) && <Loading />}
            {!loading && !loading1 && (
              <CustomerForm
                enableReinitialize
                initialValues={customerData}
                handleSubmit={handleSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditCustomer;
