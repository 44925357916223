import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useStoreState } from 'easy-peasy';

import Seo from '../components/Seo';
import DashboardLayout from '../components/dashboard/DashboardLayout';
import StatsContainer from '../components/dashboard/StatsContainer';
import ProfileCard from '../components/dashboard/ProfileCard';

const allStatsQuery = gql`
  query allStats {
    allStats {
      agencies
      centers
      reports
      assistants
      packages
      customers
    }
  }
`;

const DashBoard = () => {
  const user = useStoreState((state) => state.user.data);

  const { data } = useQuery(allStatsQuery, {
    fetchPolicy: 'no-cache',
  });

  const allStats = data && data.allStats ? data.allStats : {};

  return (
    <DashboardLayout>
      <Seo title="Dashboard" description="Iris Center Dashboard" />
      <StatsContainer allStats={allStats} user={user} />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <hr className="border-b-2 border-gray-600 my-8 mx-4" />
        <div className="grid grid-cols-2 gap-4">
          <ProfileCard user={user} />
          {/* <ProfileCard /> */}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashBoard;
