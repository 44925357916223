import { gql, useQuery } from '@apollo/client';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { AgencyTable } from '../../components/tables';
import { SearchBar } from '../../components/elements';

const allAgencyQuery = gql`
  query allUsers {
    allUsers(type: agency) {
      id
      userId
      email
      name
      status
      telephone
      reportsLimit
      address {
        country
        state
        city
        address
      }
    }
  }
`;

const AllAgency = () => {
  const { data } = useQuery(allAgencyQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const allAgency = data && data.allUsers ? data.allUsers : [];

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <h1 className="text-4xl text-center text-green-600 border-b-2 pb-2	m-5">
        All Agency
      </h1>
      <div className="w-1/2 pb-4 mt-10 md:px-0 ml-2">
        <SearchBar />
      </div>
      <div className="w-full pb-4 md:px-0 md-8 mb-16 b text-gray-800 leading-normal">
        <AgencyTable data={allAgency} />
      </div>
    </DashboardLayout>
  );
};
export default AllAgency;
