import { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';

import { isEmpty } from 'lodash';

import Seo from '../../../components/Seo';

import DashboardLayout from '../../../components/dashboard/DashboardLayout';
import { ReportsTable } from '../../../components/tables';
// import { SearchBar } from '../../components/elements';

import { EmptyState, Loading } from '../../../components/elements';

const allReportsQuery = gql`
  query allReports(
    $status: Status
    $hasInitiatedBy: Boolean
    $hasAgencyApproval: Boolean
    $hasAgencySendApproval: Boolean
    $hasAgency: Boolean
  ) {
    allReports(
      status: $status
      hasInitiatedBy: $hasInitiatedBy
      hasAgencyApproval: $hasAgencyApproval
      hasAgencySendApproval: $hasAgencySendApproval
      hasAgency: $hasAgency
    ) {
      id
      hasAgencyApproval
      hasAgencySendApproval
      customer {
        id
        customerId
        name
        age
        gender
      }
      status
      createdAt
      agency {
        name
      }
      initiatedBy {
        name
        userId
      }
    }
  }
`;

const updateReportMutation = gql`
  mutation updateReport($id: ID!, $input: ReportInput) {
    editReport(id: $id, input: $input) {
      id
    }
  }
`;

const CenterReports = ({ match }) => {
  // const user = useStoreState((state) => state.user.data);

  const { status } = match.params;

  const objFind = {};

  if (status === 'send') {
    objFind.hasAgency = true;
    objFind.hasAgencyApproval = false;
  }

  const { data, loading, refetch } = useQuery(allReportsQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { ...objFind },
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(updateReportMutation);

  const allReports = data && data.allReports ? data.allReports : [];

  const handleUpdateReport = async (val) => {
    const obj = {
      status: val.status,
      customer: val.customer,
    };

    if (val.hasAgencyApproval) {
      obj.hasAgencyApproval = val.hasAgencyApproval;
    }

    await executeMutation({
      variables: {
        id: val.id,
        input: obj,
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Report  can not be updated', 'error');
    }
    if (data1) {
      refetch();
      swal('Congrats', 'Report updated', 'success');
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="All Reports" description="Iris Center All Reports" />
      <h1 className="text-4xl text-center text-green-600 border-b-2 pb-2	m-5">
        All Reports
      </h1>
      {isEmpty(allReports) && <EmptyState />}
      {(loading1 || loading) && <Loading />}
      {!isEmpty(allReports) && (
        <div className="w-full pb-4 md:px-0 md-8 mb-16 b text-gray-800 leading-normal">
          <ReportsTable
            data={allReports}
            handleUpdateReport={handleUpdateReport}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default CenterReports;
