import { FaThList } from 'react-icons/fa';

const StatsCard = ({ title, icon, value, iconBackground, background }) => {
  return (
    <div className="w-full md:w-1/2 xl:w-1/3 p-6">
      <div className={` border-b-4 ${background}  rounded-lg shadow-xl p-5`}>
        <div className="flex flex-row items-center">
          <div className="flex-shrink pr-4">
            <div
              className={`rounded-full p-5 ${iconBackground} text-2xl text-white`}
            >
              {icon || <FaThList />}
            </div>
          </div>
          <div className="flex-1 text-right md:text-center">
            <h5 className="font-bold uppercase text-gray-600">{title}</h5>
            <h3 className="font-bold text-3xl">{value}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
