import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    // marginTop: 40,
  },
  text: {
    marginTop: 5,
    fontSize: 14,
  },
  infoContainer: {
    marginBottom: 15,
  },
});

const Footer = () => (
  <View style={styles.container} wrap={false}>
    <Text
      style={[styles.text, { fontWeight: 500, textDecoration: 'underline' }]}
    >
      NB- PLEASE CORRELATE CLINICALLY. NOT FOR MEDICO LEGAL PURPOSE.
    </Text>
    <View style={{ marginTop: 30, marginBottom: 10 }}>
      <Text
        style={{ fontSize: 16, fontWeight: 500, textDecoration: 'underline' }}
      >
        Prepared and checked By
      </Text>
    </View>
    <View style={styles.infoContainer}>
      <Text style={[styles.text, { fontWeight: 900 }]}>DR AJIT SINGH</Text>
      <Text style={[styles.text, { fontWeight: 900 }]}>
        BEMS, ADEH, RHMP, Msc (India),
      </Text>
      <Text style={[styles.text, { fontWeight: 900 }]}>D.ird. D.Hom (UK)</Text>
    </View>
    <View style={styles.infoContainer}>
      <Text style={[styles.text, { fontWeight: 900 }]}>Simona Sottilli</Text>
      <Text style={[styles.text, { fontWeight: 900 }]}>
        Naturopath and Iridologist (Italy)
      </Text>
    </View>
    <View style={styles.infoContainer}>
      <Text
        style={[styles.text, { fontWeight: 900, textDecoration: 'underline' }]}
      >
        Globally in the Association of
      </Text>
      <View style={{ marginLeft: 5, marginTop: 5 }}>
        <Text style={styles.text}>* www.KHRL.EU- Estonia</Text>
        <Text style={styles.text}>* www.ERDC.NO - Norway</Text>
        <Text style={styles.text}>* www.ERDOINDIA.ORG -India</Text>
        <Text style={styles.text}>* www.Mattei Herbal Pharma (UK)</Text>
      </View>
    </View>
  </View>
);

export default Footer;
