import React, { useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';

import { TableHead, Tag } from '../elements';

const tableHeadData = [
  { name: 'S.NO.', width: 'w-1' },
  { name: 'Agency ID', width: 'w-1/5' },
  { name: 'Name' },
  { name: 'Phone NO.', width: 'w-1' },
  { name: 'Email-ID' },
  { name: 'Location', width: 'w-3/5' },
  { name: 'Report' },
  { name: 'Status' },
  { name: 'More' },
];

const Tr = styled.tr`
  /* td {
    width: 20% !important;
  } */
`;

const AgencyTable = ({ data }) => {
  const [show, setShow] = useState(null);

  const groupedClass =
    'bg-gray-100 text-gray-900 block px-4 py-2 cursor-pointer text-xs account-link hover:text-white rounded-md m-1 transition duration-500 ease-in-out transform';

  return (
    <div className="shadow-xl	 px-1 md:px-1 pt-4 md:pt-7 pb-5 overflow-y-auto">
      <table className="w-full border-collapse border  ">
        <TableHead data={tableHeadData} />
        <tbody className="w-full">
          {data.map((item, index) => (
            <Tr
              className="h-20 text-sm leading-4	 text-gray-800 bg-white hover:bg-gray-100 	divide-x divide-gray-100 border-2"
              key={item.id}
            >
              <td className="p-2">{index + 1}</td>
              <td className="p-2">{item.userId || ''}</td>
              <td className="p-2">{item.name || ''}</td>
              <td className="p-2">{item.telephone || ''}</td>
              <td className="p-2">{item.email || ''}</td>
              <td className="p-2 ">
                {item.address.address || ' '}
                {item.address.city || ' '}
                {item.address.state || ' '}
                {item.address.country || ' '}
              </td>
              <td className="p-2">{item.reportsLimit || ''}</td>
              <td className="p-2">
                <Tag status={item.status} />
              </td>
              <td className="p-2 2xl:px-0">
                {show === index ? (
                  <button
                    type="button"
                    onClick={() => setShow(null)}
                    className="focus:outline-none pl-1 text-xl"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setShow(index)}
                    className="focus:outline-none pl-1 text-xl"
                  >
                    <BiDotsHorizontalRounded />
                  </button>
                )}
                {show === index && (
                  <div className="dropdown-content bg-white shadow w-24 absolute z-30 right-0 mr-6">
                    <div className={groupedClass}>
                      <p>Details</p>
                    </div>
                    <div className={groupedClass}>
                      <p>Edit</p>
                    </div>
                    <div className={groupedClass}>
                      <p>Delete</p>
                    </div>
                    <div className={groupedClass}>
                      <p>Block</p>
                    </div>
                  </div>
                )}
              </td>
            </Tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default AgencyTable;
