import { FaThList } from 'react-icons/fa';

import StatsCard from './StatsCard';

const StatsContainer = ({ allStats, user }) => {
  const data = [
    {
      id: '1',
      value: allStats.agencies || 0,
      title: 'Total agency',
      color: 'blue',
      iconBackground: 'bg-blue-500',
      background: 'bg-gradient-to-b from-blue-200 to-blue-100 border-blue-500',
      access: 'admin',
    },
    {
      id: '2',
      value: allStats.centers || 0,
      title: 'Total centers',
      color: 'pink',
      iconBackground: 'bg-pink-500',
      background: 'bg-gradient-to-b from-pink-200 to-pink-100 border-pink-500',
      access: 'admin',
    },
    {
      id: '3',
      value: allStats.packages || 0,
      title: 'Total packages',
      color: 'yellow',
      iconBackground: 'bg-yellow-500',
      background:
        'bg-gradient-to-b from-yellow-200 to-yellow-100 border-yellow-500',
      access: 'admin',
    },
    {
      id: '5',
      value: allStats.assistants || 0,
      title: 'Total assistants',
      color: 'red',
      iconBackground: 'bg-red-500',
      background: 'bg-gradient-to-b from-red-200 to-red-100 border-red-500',
      access: 'admin',
    },
    {
      id: '6',
      value: allStats.customers || 0,
      title: 'Total Customers',
      color: 'indigo',
      iconBackground: 'bg-indigo-500',
      background:
        'bg-gradient-to-b from-indigo-200 to-indigo-100 border-indigo-500',
      access: 'all',
    },
    {
      id: '4',
      value: allStats.reports || 0,
      title: 'Total Reports',
      color: 'green',
      iconBackground: 'bg-green-500',
      background:
        'bg-gradient-to-b from-green-200 to-green-100 border-green-500',
      access: 'all',
    },

    {
      id: '7',
      value: '0',
      title: 'Reports Approved',
      color: 'indigo',
      iconBackground: 'bg-indigo-500',
      background:
        'bg-gradient-to-b from-indigo-200 to-indigo-100 border-indigo-500',
      access: 'all',
    },
  ];

  return (
    <div className="flex flex-wrap">
      {data.map((item) => {
        return item.access === 'all' || item.access === user.type ? (
          <StatsCard
            key={item.id}
            title={item.title}
            value={item.value}
            icon={<FaThList />}
            iconBackground={item.iconBackground}
            background={item.background}
          />
        ) : null;
      })}
    </div>
  );
};

export default StatsContainer;
