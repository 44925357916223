const fields = {
  constitution: [
    {
      // 1
      id: 'constitution-1',
      label: 'Haematogenic (person bearing a sanguine constitution)',
    },
    {
      // 2
      id: 'constitution-2',
      label: 'Lymphatic ( person bearing a Lymphatic constitution)',
    },
    {
      // 3
      id: 'constitution-3',
      label: 'Mixed ( person bearing a Mixed constitution)',
    },
  ],
  dNADominance: [
    {
      // 1
      id: 'dNADominance-1',
      label: 'Paternal (Person having a Paternal DNA dominance)',
    },
    {
      // 2
      id: 'dNADominance-2',
      label: 'Maternal ( Person bearing maternal DNA Dominance)',
    },
  ],
  constitutionalQuality: [
    {
      // 1
      id: 'constitutionalQuality-1',
      label: '1-2 (Good)',
    },
    {
      // 2
      id: 'constitutionalQuality-2',
      label: '3-7 (Average)',
    },
    {
      // 3
      id: 'constitutionalQuality-3',
      label: '8-9 (Weak)',
    },
    {
      // 4
      id: 'constitutionalQuality-4',
      label: '10 (Extremely Weak)',
    },
  ],
  collaretteIntegrity: [
    {
      // 1
      id: 'collaretteIntegrity-1',
      label: 'Normal – Collarete is Well Integrated.',
    },
    {
      // 2
      id: 'collaretteIntegrity-2',
      label: 'Restricted',
      details:
        'Indicating tendency to constipation and sluggishness of intestine. Stagnation of lymphatic fluid around the intestine within the great number of Lymph nodes located on the exterior of the large intestine. Tendency to ear, nose, and throat disorder. Possible under activity of the pineal, thymus gland and hypothalamus. An history of dysbiosis, lack of breast feeding as in infant, hyperactivity of adrenal gland, increase lymphocyte activity, predominance of cell mediated immunity, tendency to speedy depletion of vitamin C or zinc. Embryologically Indicating the history of anxiety in Utero. Psych emotional issue include general feeling of insecurity ,fear of dependence and acceptance of wisdom.',
      values: [
        // 2.1
        {
          id: 'collaretteIntegrity-2.1',
          label: 'Right',
        },
        // 2.2
        {
          id: 'collaretteIntegrity-2.2',
          label: 'Left',
        },
        // 2.3
        {
          id: 'collaretteIntegrity-2.3',
          label: 'Both',
        },
      ],
    },
    {
      // 3
      id: 'collaretteIntegrity-3',
      label: 'Distended',
      details:
        'Indicate an increased stomach capacity, tenderness to bloating, flatulence and speedy transit time of food through the digestive tract and poor assimilation of he nutrients., dominance of parasympathetic nervous system.',
      values: [
        {
          // 3.1
          id: 'collaretteIntegrity-3.1',
          label: ' Lateral distention (Rx)',
          details:
            'Indicates tendency to respiratory problem such as asthma due to intestinal immunological changes.',
        },
        {
          // 3.2
          id: 'collaretteIntegrity-3.2',
          label: 'Inferior temporal lateral distension (Rx)',
          values: [
            {
              // 3.2.1
              id: 'collaretteIntegrity-3.2.1',
              label:
                'Indicates fear in connection to inferiority, ileo-caecal valve problems. Disturbances of liver function weak intestinal peristalsis, IBS and haemorrhoids are possible resultant condition.',
            },
            {
              // 3.2.2
              id: 'collaretteIntegrity-3.2.2',
              label: 'Dysmenorrhea',
              details:
                'Indicates paternal conflicts, bronchial problem, fears of expression, breathlessness, thyroid gland disturbances, mood swings from joy to disappointment and pharyngitis.',
            },
          ],
        },
        {
          // 3.3
          id: 'collaretteIntegrity-3.3',
          label: 'Temporal lateral distension (Lx)',
          details:
            'Indicates paternal conflicts, bronchial problem, fears of expression, breathlessness, thyroid gland disturbances, mood swings from joy to disappointment and pharyngitis',
        },
        {
          // 3.4
          id: 'collaretteIntegrity-3.4',
          label: 'Inferior lateral distension (Lx)',
          details:
            'It indicates changes in blood sugar level, spleen, stasis of the blood, IBS and can only digest small amount of fluid dysbiosis, immune compromises, endometriosis, Period pains. anaemia during  pregnancy.',
        },
      ],
    },
    {
      // 4
      id: 'collaretteIntegrity-4',
      label: 'Ventral Distension',
      details:
        'There may be history of hemorrhoids, varicose veins, sciatica, inguinal hernia, hydrocele, benign prostatic hyperplasia, lymphoedema (localized to the legs)',
    },
    {
      // 5
      id: 'collaretteIntegrity-5',
      label: 'Bilateral Distention',
      details:
        'Intestinal dysbiosis due to alteration in the function of the Peyers patches, mucosa associated lymphoid tissue. Respiratory problems may results because of this, including allergic form of asthma or rhinitis. Sometime the elevation of antibodies IgM, IgE, IgG and  interleukin, macrophages plus mast cell degranulation. Sometime neuromuscular problem and required bioflavonoid such as rutin, vitamin K and B complex, amino sugar glucosamine sulphate,zinc, magnesium and potassium.',
    },
    {
      // 6
      id: 'collaretteIntegrity-6',
      label: 'Sectional Wavy Distension:',
      details:
        ' Indicates history of tonsillitis, cystitis, nephrites, pelvic inflammatory disease, orchitis, rhinitis and sinusitis.',
    },
    {
      // 7
      id: 'collaretteIntegrity-7',
      label: 'Protuberance (Bulge In Collarette)',
      values: [
        {
          // 7.1
          id: 'collaretteIntegrity-7.1',
          label:
            'Accompanied with the rarefaction, shading and gray colour to the underline iris stroma then a diverticula tendency can be suspected',
        },
        {
          // 7.2
          id: 'collaretteIntegrity-7.2',
          label:
            'Accompanied with crypts or a honeycomb lacuna, then a parasitical or fungal infestation may be present.',
        },
        {
          // 7.3
          id: 'collaretteIntegrity-7.3',
          label:
            'With pupillary mydriasis indicates the patient with history of mononucleosis',
        },
        {
          // 7.4
          id: 'collaretteIntegrity-7.4',
          label:
            'Encapsulated lacuna at 40 angles in the right iris can relate to the pancreatic pathology',
        },
      ],
    },
    {
      // 8
      id: 'collaretteIntegrity-8',
      label: 'Romheld Syndrome',
      details:
        'Indicating cardio abdominal sub type constitution, physical result from these can be heart pain, filling a pressure below against the stomach, trapped wind, filing of full stomach angina pectoris, palpitation with restricted breathing',
    },
    {
      // 9
      id: 'collaretteIntegrity-9',
      label: 'Funnel Sign',
      details:
        'Indicate to tendency to weakness in the abdominal muscles possible hiatus hernia  poor vitamin and mineral absorption, embryonic displacement of surrounding organs from the intestine.',
    },
    {
      // 10
      id: 'collaretteIntegrity-10',
      label: 'Local Indentation Of The Collarette',
      values: [
        {
          // 10.1
          id: 'collaretteIntegrity-10.1',
          label:
            'It indicates compromise of MALT and GALT, possible parasite, inflammation within the intestinal tract, dysbiosis and autoimmunity such as SLE or rheumatoid arthritis. Suppressed emotion of anger leading to depression.',
        },
        {
          // 10.2
          id: 'collaretteIntegrity-10.2',
          label: 'Splenomegaly found at 5-20 angle at left iris.',
        },
        {
          // 10.3
          id: 'collaretteIntegrity-10.3',
          label:
            'Appendicitis in family or own medical history when found at angle of 32-36 at right iris',
        },
        {
          // 10.4
          id: 'collaretteIntegrity-10.4',
          label:
            'Pancreatitis when located at 20-40 angles in either right or left iris',
        },
        {
          // 10.5
          id: 'collaretteIntegrity-10.5',
          label:
            'Appearing frontally considering possible birth trauma changes in interior pituitary, hypothalamus and pineal gland',
        },
      ],
    },
    {
      // 11
      id: 'collaretteIntegrity-11',
      label: 'Frontal Indention',
      values: [
        {
          // 11.1
          id: 'collaretteIntegrity-11.1',
          label: 'Revival sign of depression in family history',
        },
        {
          // 11.2
          id: 'collaretteIntegrity-11.2',
          label:
            'There may be learning difficulties, migraine headache and general neurological disturbances usually due to cranial trauma.',
        },
      ],
    },
    {
      // 12
      id: 'collaretteIntegrity-12',
      label: 'Zig-Zag',
      values: [
        {
          // 12.1
          id: 'collaretteIntegrity-12.1',
          label:
            'It indicates spasm and cramps within the intestinal tract and muscular system throughout the body. Tendencies to IBS, accelerated transit time of food, colic, ulcerative colitis, sigmoid polyposis and colitis. Intolerance to dairy products and white flour products due to increased mast cell degranulation. It tendency to mood swings, anxiety, conflicts with the mother and not following joy in life.',
        },
        {
          // 12.2
          id: 'collaretteIntegrity-12.2',
          label:
            'Decreases in intestinal and cerebral hormonal secretion when under stress or the immune system challenged, leading to decrease in substance P, pancreatic secretion, gastric acid, somatostatin which influences growth hormone and VIP which influences the gut associated lymphoid tissue and pancreas. Vitamin B12 deficiency is also possible.',
        },
        {
          // 12.3
          id: 'collaretteIntegrity-12.3',
          label: 'Dysmenorrhoea and menorrhea',
        },
      ],
    },
    {
      // 13
      id: 'collaretteIntegrity-13',
      label: 'Partial Zig Zag',
      values: [
        {
          // 13.1
          id: 'collaretteIntegrity-13.1',
          label:
            ' Indicates modern disturbances of the gastrointestinal tract and alteration to the MALT and friendly flora of the bowel.',
        },
        {
          // 13.2
          id: 'collaretteIntegrity-13.2',
          label:
            'Frontally indicating hypothalamic changes, anxiety, memory problems and insomnia.',
        },
        {
          // 13.3
          id: 'collaretteIntegrity-13.3',
          label:
            'Temporally indicating alteration in the extremities of constipation and diarrhoea, faulty digestion.Diarrhoea is usually conscience of chronic constipation.',
        },
        {
          // 13.4
          id: 'collaretteIntegrity-13.4',
          label:
            ' Ventrally indicating problems with sigmoid, colon. Rectum, anus, ileo-caecal valve, cold feet, and leg cramps',
        },
      ],
    },
    {
      // 14
      id: 'collaretteIntegrity-14',
      label: 'Thickened Collarette',
      details:
        'Indicting over acidity, allergies, food intolerances, arthritis, and other inflammatory conditions. Sometime nervous irritability, congestion of intestinal lymph node, compromise of immune system and disturbances in calcium metabolism',
    },
    {
      // 15
      id: 'collaretteIntegrity-15',
      label: 'Thin Collarette',
      details:
        'Indicating high emotions and sensitivity with delicate nervous system and need to replenish levels of magnesium and vitamin B. endocrine glandular secretions may inhibited and adrenal gland particularly are prone to stress in addition to asthma, food intolerance leaky gut syndrome and dysbiosis',
    },
    {
      // 16
      id: 'collaretteIntegrity-16',
      label: 'Absent Collarette',
      details:
        'Indicating feeling of being overwhelmed, hopelessness, emotional vulnerability, dignities autonomic nervous system activity. Inhibition in nutrients assimilation, enzyme insist which can result in food intolerance especially from dairy products. Intestinal colic and bloating, nausea, reflux, appetite swings and excessive product of catarrh may ensue. Sometime also chronic fatigue syndrome.',
    },
    {
      // 17
      id: 'collaretteIntegrity-17',
      label: 'Double Collarette',
      details:
        'Indicating irritations of mesenteric lymphatic, nervous system. Migraine, depression renal disturbances may be indicating. Emotionally a person will tend to have fears of not doing, being good or enough. These may manifest itself as an "emotional bowel" or in general medical term "irritable bowel syndrome" or IBS',
    },
    {
      // 18
      id: 'collaretteIntegrity-18',
      label: 'Triple Collarette',
      details: 'Indicating psychosomatic complications. Trigeminal Neuralgia.',
    },
    {
      // 19
      id: 'collaretteIntegrity-19',
      label: 'Squared Collarette',
      details:
        ' Indicating family history of Autoimmune Diabetes Mellitus with destruction of the beta cells in the pancreas.',
    },
    {
      // 20
      id: 'collaretteIntegrity-20',
      label: 'Collarette Rope',
      details:
        'Koch s Sign: Indicating History of Tuberculosis in the family background as well history of miscarriage and ectopic pregnancies in the family background. There may be the tendency to vitamin E deficiency. Also indicating Hashimotos disease sometime',
    },
    {
      // 21
      id: 'collaretteIntegrity-21',
      label: 'Sea Foam Collarette',
      details:
        'Indicating Alteration of gastrointestinal hormones. Gastric acidity and  enzymatic changes, arteriosclerosis, anaemia, lymphadenopathy, kidney disturbances and general energetic stomach and intestinal imbalances apparent within the medical history of the patient.',
    },
    {
      // 22
      id: 'collaretteIntegrity-22',
      label: 'Frontal Cats Ears Collarette',
      details:
        'Indicating a marked genetic predisposition in all iris types to insomnia, Depression, Anxiety',
    },
    {
      // 23
      id: 'collaretteIntegrity-23',
      label: 'Linear Collarette',
      details:
        ' It predisposes the individual to erratic blood sugar levels and endocrine- related reactions',
    },
    {
      // 24
      id: 'collaretteIntegrity-24',
      label: 'Collarette Bridge',
      details:
        'Indicating Progesterone deficiency related concerns. Blood sugar imbalance history, Enhanced sensitivity to stress. Autoimmunity, leak gut syndrome',
    },
    {
      // 25
      id: 'collaretteIntegrity-25',
      label: 'Progesterone Collarette Indicating',
      values: [
        {
          // 25.1
          id: 'collaretteIntegrity-25.1',
          label: 'oestrogen dominance conditions',
        },
        {
          // 25.2
          id: 'collaretteIntegrity-25.2',
          label: 'Progesterone-prolactin imbalance',
        },
        {
          // 25.3
          id: 'collaretteIntegrity-25.3',
          label: 'Testosterone-Progesterone Imbalance.',
        },
        {
          // 25.4
          id: 'collaretteIntegrity-25.4',
          label: 'Extreme dysglycemia',
        },
        {
          // 25.5
          id: 'collaretteIntegrity-25.5',
          label: 'Subfertility',
        },
        {
          // 25.6
          id: 'collaretteIntegrity-25.6',
          label: 'Autoimmunity',
        },
      ],
    },
  ],

  presenceOfLacuna: [
    {
      // 1
      id: 'presenceOfLacuna-1',
      label: 'Large Intestine',
    },
    {
      // 2
      id: 'presenceOfLacuna-2',
      label: 'Heart',
    },
    {
      // 3
      id: 'presenceOfLacuna-3',
      label: 'Endocrine Gland',
    },
    {
      // 4
      id: 'presenceOfLacuna-4',
      label: 'Lungs',
    },
    {
      // 5
      id: 'presenceOfLacuna-5',
      label: 'Ear',
    },
    {
      // 6
      id: 'presenceOfLacuna-6',
      label: 'Cerebellum',
    },
    {
      // 7
      id: 'presenceOfLacuna-7',
      label: 'Pancreas',
    },
    {
      // 8
      id: 'presenceOfLacuna-8',
      label: 'Adrenals',
    },
    {
      // 9
      id: 'presenceOfLacuna-9',
      label: 'Small Intestine',
    },
    {
      // 10
      id: 'presenceOfLacuna-10',
      label: 'Uterus',
    },
    {
      // 11
      id: 'presenceOfLacuna-11',
      label: 'Pituitary Gland',
    },
    {
      // 12
      id: 'presenceOfLacuna-12',
      label: 'Ovary',
    },
    {
      // 13
      id: 'presenceOfLacuna-13',
      label: 'Testes',
    },
    {
      // 14
      id: 'presenceOfLacuna-14',
      label: 'Hypothalamus',
    },
    {
      // 15
      id: 'presenceOfLacuna-15',
      label: 'Hypothalamus',
    },
    {
      // 16
      id: 'presenceOfLacuna-16',
      label: 'Gall Bladder',
    },
    {
      // 17
      id: 'presenceOfLacuna-17',
      label: 'Aorta',
    },
    {
      // 18
      id: 'presenceOfLacuna-18',
      label: 'Urinary Bladder',
    },
    {
      // 19
      id: 'presenceOfLacuna-19',
      label: 'Kidneys',
    },
    {
      // 20
      id: 'presenceOfLacuna-20',
      label: 'Breast',
    },
    {
      // 21
      id: 'presenceOfLacuna-21',
      label: 'Sinus',
    },
    {
      // 22
      id: 'presenceOfLacuna-22',
      label: 'Pineal Gland',
    },
    {
      // 23
      id: 'presenceOfLacuna-23',
      label: 'Thymus Gland',
    },
  ],

  typesOfLacunae: [
    {
      // 1
      id: 'typesOfLacunae-1',
      label: 'Open Lacuna ',
      details:
        'There is genetic tendency to organ insufficiency.There may be sudden organ collapse due to abusive lifestyle or later in life',
    },
    {
      // 2
      id: 'typesOfLacunae-2',
      label: 'Closed lacuna',
      values: [
        {
          // 2.1
          id: 'typesOfLacunae-2.1',
          label:
            ' Closed lacunae indicate the likelihood of a condition resolved in previous generations.',
        },
        {
          // 2.2
          id: 'typesOfLacunae-2.2',
          label:
            'Dark Sign in a closed lacuna indicate low resistance. There could be chronic insufficiency due to oxidative stress of an organ or system.',
        },
      ],
    },
    {
      // 3
      id: 'typesOfLacunae-3',
      label: 'Asparagus Lacuna',
      values: [
        {
          // 3.1
          id: 'typesOfLacunae-3.1',
          label: 'Frontal Asparagus Lacuna',
          values: [
            {
              // 3.1.1
              id: 'typesOfLacunae-3.1.1',
              label: 'Indicate Nasal polyps',
            },
            {
              // 3.1.2
              id: 'typesOfLacunae-3.1.2',
              label: 'Adenoma of the pituitary gland.',
            },
            {
              // 3.1.3
              id: 'typesOfLacunae-3.1.3',
              label: 'Pinealoma',
            },
            {
              // 3.1.4
              id: 'typesOfLacunae-3.1.4',
              label: 'Hemiplegic Migraines.',
            },
          ],
        },
        {
          // 3.2
          id: 'typesOfLacunae-3.2',
          label: 'Ventrally Asparagus Lacuna',
          values: [
            {
              // 3.2.1
              id: 'typesOfLacunae-3.2.1',
              label: 'Salpingitis',
            },
            {
              // 3.2.2
              id: 'typesOfLacunae-3.2.2',
              label:
                'Carcinoma, Polyposis, Myeloma or cysts in the uterus (Fibroids) ,Ovary or cervix (Cervical dysplasia) in females.',
            },
            {
              // 3.2.3
              id: 'typesOfLacunae-3.2.3',
              label:
                'Carcinoma or Inflammatory concerns wih the prostate, testes or epididymis in males.',
            },
            {
              // 3.2.4
              id: 'typesOfLacunae-3.2.4',
              label: 'Nutritional Requirements',
              values: [
                {
                  // 3.2.4.1
                  id: 'typesOfLacunae-3.2.4.1',
                  label: 'Potassium',
                },
                {
                  // 3.2.4.2
                  id: 'typesOfLacunae-3.2.4.2',
                  label: 'Essential Fatty Acids',
                },
                {
                  // 3.2.4.3
                  id: 'typesOfLacunae-3.2.4.3',
                  label: 'Selenium',
                },
                {
                  // 3.2.4.4
                  id: 'typesOfLacunae-3.2.4.4',
                  label: 'Germanium',
                },
                {
                  // 3.2.4.5
                  id: 'typesOfLacunae-3.2.4.5',
                  label: 'Phosphoric acid',
                },
                {
                  // 3.2.4.6
                  id: 'typesOfLacunae-3.2.4.6',
                  label: 'Vanadium',
                },
                {
                  // 3.2.4.7
                  id: 'typesOfLacunae-3.2.4.7',
                  label: 'Molybdenum',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      // 4
      id: 'typesOfLacunae-4',
      label: 'Honey Comb Lacuna',
      values: [
        {
          // 4.1
          id: 'typesOfLacunae-4.1',
          label:
            'It shows disturbed cellular metabolism resulting in insufficient tissues nutrition,tendencies to severe weakness.',
        },
        {
          // 4.2
          id: 'typesOfLacunae-4.2',
          label:
            'Indicate cystic development if found I kidney and pancreatic topographies.',
        },
      ],
    },
    {
      // 5
      id: 'typesOfLacunae-5',
      label: 'Jellyfish Lacuna',
      details:
        'Topostabile Sign for the bronchi, lungs, or kidneys. It is illustrative of a genetic history or organ  or connective tissue problems in those areas.',
    },
    {
      // 6
      id: 'typesOfLacunae-6',
      label: 'Thyroxine Lacuna',
      values: [
        {
          // 6.1
          id: 'typesOfLacunae-6.1',
          label:
            'Genetic tendency to hypothyroidism or underactive thyroid function.',
        },
        {
          // 6.2
          id: 'typesOfLacunae-6.2',
          label:
            'Hyperthyroidism. (Presence of crypt or radical indention of the collarette)',
        },
      ],
    },
    {
      // 7
      id: 'typesOfLacunae-7',
      label: 'Circular Lacuna',
      details:
        'It is often present in those with diabetes insipidus. Also hormonally triggered migraine. A circular lacuna predisposes to pale skin, hypertension, oedema (especially pre-menstrually) , tachycardia and chest pains and sexual concerns, such as decreases in libido due to anxiety.',
    },
    {
      // 8
      id: 'typesOfLacunae-8',
      label: 'Shoe Lacuna',
      details:
        'The shoe Lacuna is always related to the kidney function. It indicates history of difficulties with mineral storage, fluid balance with the probability of oedematous tissues.',
    },
    {
      // 9
      id: 'typesOfLacunae-9',
      label: 'Silver Fibre lacuna',
      details:
        'Research revealed post febrile indications such as a result of streptococcal infections during childhood, Rheumatic fever or bacterial endocarditis.',
    },
    {
      // 10
      id: 'typesOfLacunae-10',
      label: 'Turpedo (cigar ) lacuna',
      details:
        'It is small topostabile sign.It indicate the disturbed level of essential fatty acids, Germanium, Zinc, selenium, potassium, sugar consumption.',
    },
    {
      // 11
      id: 'typesOfLacunae-11',
      label: 'Rhomboid lacuna',
      values: [
        {
          // 11.1
          id: 'typesOfLacunae-11.1',
          label:
            'It shows a genetic history and tendency to immunodepression. Also indicate the osteomalacia bone softening due to loss of calcium and poor assimilation of vitamin D. Sometime it related to the hypercalcaemia.',
        },
        {
          // 11.2
          id: 'typesOfLacunae-11.2',
          label:
            'Bone Marrow Changes:- Found with small cross-cross transversals @17,30 and 35 in the right iris, plus 25 and 43 in the left iris ,accompanied by black triangles on the IPB, then bone marrow changes in regard to immune system can be followed up.',
        },
      ],
    },
    {
      // 12
      id: 'typesOfLacunae-12',
      label: 'Beak lacuna',
      details:
        'A beak lacuna can be serious sign. It needs further investigation and testing like Haemoccult test, coloscopy, MRI scan, Sigmoidoscopy, Endoscopy, haematological profiles and Stool tests.',
      values: [
        {
          // 12.1
          id: 'typesOfLacunae-12.1',
          label:
            'When beak found into the collarette, penetrating its entire border or accompanied by a marked and localised indentation towards the pupil. It is an intestinal neoplasm tendency indicator',
        },
        {
          // 12.2
          id: 'typesOfLacunae-12.2',
          label: 'A straight beak points to a malignant process.',
        },
        {
          // 12.3
          id: 'typesOfLacunae-12.3',
          label:
            'The curved beak indicates a benign process involving tumours, diverticulosis or polyposis  within the gastrointestinal tract.',
        },
      ],
    },
    {
      // 13
      id: 'typesOfLacunae-13',
      label: ' Thymus Lacuna',
      details:
        'Mostly found in individuals with inhibition of a cell mediated immune responses,susceptibility to infection due to poor infant nutrition or lak of breastfeeding as a baby,autoimmune myasthenia gravis, possible tumour growth in the thymus gland or a general diminished T-cell count.',
    },
    {
      // 14
      id: 'typesOfLacunae-14',
      label: 'One-sided lacuna',
      details:
        'Indicate the inflammatory and oedematous tendencies in the individual.',
    },
    {
      // 15
      id: 'typesOfLacunae-15',
      label: 'Negative Lacuna',
      details:
        'It indicates metabolic dysfunction in relation to elevated levels of uric acid irritating the heart, kidneys, joints and connective tissues.',
    },
    {
      // 16
      id: 'typesOfLacunae-16',
      label: 'Lance lacuna',
      values: [
        {
          // 16.1
          id: 'typesOfLacunae-16.1',
          label: 'It can often interpret as a defect sign.',
        },
        {
          // 16.2
          id: 'typesOfLacunae-16.2',
          label:
            'Present with a pigment granulation so we can have consideration for the development of benign tumour or adenoma.',
        },
      ],
    },
    {
      // 17
      id: 'typesOfLacunae-17',
      label: 'Daisy Petal lacuna',
      details:
        'This sign is usually located @ 30 ‘ and attest to adrenal gland insufficiency, inflammatory intestinal problems and enzymatic compromises. There may be tumours of the adrenal cortex or kidney .',
    },
    {
      // 18
      id: 'typesOfLacunae-18',
      label: 'Solitary Collarette Lacuna',
      values: [
        {
          // 18.1
          id: 'typesOfLacunae-18.1',
          label:
            'Located externally on the collarette relate to endocrine functions and glands.',
        },
        {
          // 18.2
          id: 'typesOfLacunae-18.2',
          label:
            'Located internally to the collarette indicate digestive, embryological topography and deep lymphatic concern.',
        },
      ],
    },
    {
      // 19
      id: 'typesOfLacunae-19',
      label: 'Double Lacuna',
      values: [
        {
          // 19.1
          id: 'typesOfLacunae-19.1',
          label:
            'The sign indicate a tendency and genetic history to benign adenoma and cystic developments, primarily with hypofunction of the adrenal cortex, prostate gland, ovaries (in Female) and even the renal system.',
        },
        {
          // 19.2
          id: 'typesOfLacunae-19.2',
          label:
            'If accompanied with a vascular or regular transversal then this suggests adenoma or benign cystic formations in the breast (in female) or endocrine gland',
        },
      ],
    },
    {
      // 20
      id: 'typesOfLacunae-20',
      label: 'Gignatic lacuna',
      details:
        'It shows a family history of and tendency to diabetes mellitus. There may also be faulty metabolism of carbohydrates.',
    },
    {
      // 21
      id: 'typesOfLacunae-21',
      label: 'Leaf lacuna',
      details:
        'It relates to the function of the endocrine system and tendency t hormonal imbalance,with particular emphasis on the HP axis',
    },
    {
      // 22
      id: 'typesOfLacunae-22',
      label: 'Stairstep Lacuna',
      details:
        'It is a serious marker, and will evidence a familial history of intestinal ( may relate to another organ or glands also) tumors, gastric ulcerations and intestinal polyposis',
    },
    {
      // 23
      id: 'typesOfLacunae-23',
      label: 'The internal lacuna',
      details:
        'It indicate severe hormonal and blood sugar concerns ,involving the gastrointestinal endocrine system',
    },
    {
      // 24
      id: 'typesOfLacunae-24',
      label: 'Adrenal lacuna',
      details:
        'There are possibilities involved with the HP axis and corticol adrenal role with the hormonal, immune and limbic system balance.',
    },
    {
      // 25
      id: 'typesOfLacunae-25',
      label: 'Distorted lacuna',
      details: 'There may be possibilities for intestinal Dysbiosis.',
    },
    {
      // 26
      id: 'typesOfLacunae-26',
      label: 'Pancreas lacuna (Single)',
      values: [
        {
          // 26.1
          id: 'typesOfLacunae-26.1',
          label:
            'Genetic dysregulation of blood sugar control due to bood sugar levels falling quickly when patient is out of balance with food, stress ,hormonal balance, etc.',
        },
        {
          // 26.2
          id: 'typesOfLacunae-26.2',
          label: 'Reduced adaptability.',
        },
      ],
    },
    {
      // 27
      id: 'typesOfLacunae-27',
      label: ' Pancreas Lacuna (More than single)',
      details: 'There may be strong tendency towards hypoglycaemia.',
    },
    {
      // 28
      id: 'typesOfLacunae-28',
      label: 'Double pancreatic lacuna',
      details: 'This indicates a genetic tendency to pancreatic Cysts.',
    },
    {
      // 29
      id: 'typesOfLacunae-29',
      label: 'Divided lacuna',
      details: 'It indicate a tendency to atrophy and hardening of tissues',
    },
  ],

  presenceOfCrypts: [
    // 1
    {
      id: 'presenceOfCrypts-1',
      label: 'Yes',
      details:
        'Crypts Indicate a tendency to anaerobic inner milieu ,dysbiosis, compromised detoxification, elevation of oxidative stress profiles, impoverished assimilation of vitamins and minerals.',
    },
    // 2
    {
      id: 'presenceOfCrypts-2',
      label: 'NO',
    },
    // 4
    { id: 'presenceOfCrypts-4', label: 'Gastrointestinal Crypt' },
    // 5
    {
      id: 'presenceOfCrypts-5',
      label: 'Multiple Crypt',
      details:
        'In The Cilliary Iris Around The Peripheral Lymphatic And Mesenchyma Circular Zone attest to immunological challenges within the connective tissues , lymphatics and Mesenchyma.',
    },
    // 6
    {
      id: 'presenceOfCrypts-6',
      label: 'Solitary Crypts On The External Border Of The Collarette',
      details: 'Are Common Place For HormonalDisturbance',
    },
    // 7
    {
      id: 'presenceOfCrypts-7',
      label: 'Solitary Crypt On The Collarette',
      details:
        'Recommended attention for appendix problem, constipation,peritonitis.',
    },
    // 8
    {
      id: 'presenceOfCrypts-8',
      label: 'Crypt In The Heart Topography',
      details:
        'May Required cardiological investigation, Bacterial endocarditis is a very real possibility.',
    },
  ],

  // new changes

  mammillations: [
    // 1
    {
      id: 'mammillations-1',
      label: 'Mammillations',
      details:
        'Mammillations: Indicating predominance of previous or current autoimmune or hypersensitivity type reactions of the immune system. These are the signs for elevated intestinal and respiratory immune responses of the macrophages',
    },
  ],

  pupillaryBordersInnerAndOuter: [
    // 1
    {
      id: 'pupillaryBordersInnerAndOuter-1',
      label: 'Hypertrophy',
      details:
        '(Indicating overactivity of the physical and emotional bodies. Allergies and autoimmune conditions such as Rheumatoid arthritis, Graves disease,  hyperthyroidism,vitiligo, systemic lupus, scleroderma and diabetes may be the conditions.  There may be less absorption and utilization of the zinc, chromium, germanium, selenium,manganese and iron).Hyperactivity of the immune system .Psychologically may suppress anger to a vey deep level.',
    },
    // 2
    {
      id: 'pupillaryBordersInnerAndOuter-2',
      label: 'Atrophy',
      details:
        '(There may be problems like Tonsillitis, Hyperadrenal function,Headache due to frontal sinusitis, Constipation due to dysbiosis, hypotension, immune depression from elevated cortisol levels, herniated vertebral disc.)',
    },
    // 3
    {
      id: 'pupillaryBordersInnerAndOuter-3',
      label: 'Hypotrophy',
      details:
        'Sensitivity to environmental toxins, reduced resistance to bacterial and viral infections, Thymus dysfunction, Alteration in the lungs and colon energetic resulting in inhibited elimination, copper alterations, sinusitis, rhinitis,laryngitis, bronchitis or otitis, depression triggered by suppressed grief, concentration difficulties',
    },
    // 4
    {
      id: 'pupillaryBordersInnerAndOuter-4',
      label: 'Partial Atrophy',
      values: [
        // 4.1
        {
          id: 'pupillaryBordersInnerAndOuter-4.1',
          label:
            'Poor adaptability to stress, Genetic history and personal reactions of a hormonal basis, Neuroendocrine tendencies',
        },
        // 4.2
        {
          id: 'pupillaryBordersInnerAndOuter-4.2',
          label:
            'Lack of sleep due to serotonins/ melatonin imbalance, white coat hypertension',
        },
        // 4.3
        {
          id: 'pupillaryBordersInnerAndOuter-4.3',
          label:
            'PCOS, Endometriosis, Uterine fibroids, Extreme menopausal symptoms.',
        },
        // 4.4
        {
          id: 'pupillaryBordersInnerAndOuter-4.4',
          label: 'Male testosterone imbalance',
        },
      ],
    },
    // 5
    {
      id: 'pupillaryBordersInnerAndOuter-5',
      label: 'Mixed',
      details:
        'Tendency to susceptibility to stress due to hyperprolactinemia (HPRL) and vice versa',
    },
  ],

  imbalancedEliminatedOrgans: [
    // 1
    {
      id: 'imbalancedEliminatedOrgans-1',
      label: 'Liver',
    },
    // 2
    {
      id: 'imbalancedEliminatedOrgans-2',
      label: 'Lymphatic System',
    },
    // 3
    {
      id: 'imbalancedEliminatedOrgans-3',
      label: 'Kidneys',
    },
    // 4
    {
      id: 'imbalancedEliminatedOrgans-4',
      label: 'Lungs',
    },
    // 5
    {
      id: 'imbalancedEliminatedOrgans-5',
      label: 'Skin',
    },
    // 6
    {
      id: 'imbalancedEliminatedOrgans-6',
      label: 'Colon',
    },
  ],
  // 1

  radialFurrows: [
    // 1
    {
      id: 'radialFurrows-1',
      label: 'Major',
      details:
        '( Diminished Nerve Supply In Digestive System And Reflexive Organs And Glands )',
    },
    // 2
    {
      id: 'radialFurrows-2',
      label: 'Minor',
      details: '( Indicating Intestinal Mucosal Difficulties)',
    },
  ],
  contractedFurrows: [
    // 1
    {
      id: 'contractedFurrows-1',
      label: 'Humoral',
      details:
        'Resistance to both bacterial and viral infection and diminished absorption of vitamins  and minerals from food , Deep intestinal lymphatic congestion, affecting cellular exchanges of the  erythrocytes which create a fertile inner milieu for bacteria and viruses to capitalize and proliferate',
    },
    // 2
    {
      id: 'contractedFurrows-2',
      label: 'Peripheral Contraction Furrows',
      details:
        'Prone to multiple infectious episodes, they may have diminished skin elimination (inhibition of CALT), experience transient lymphadenopathy and may have wandering pains in Rheumatoid arthritis, polyarthritis, Polymyalgia, fibromyalgia or Ankylosing spondylitis',
    },
    // 3
    {
      id: 'contractedFurrows-3',
      label: 'Sectoral Opening',
      details: 'Stress, insomnia, disturbances of CSF and cranio-sacral rhythm',
    },
    // 4
    {
      id: 'contractedFurrows-4',
      label: 'Crossing Furrows',
      details:
        "indicating spasmodic and neurological conditions, such as Parkinson's disease, Epilepsy, Motor Neurone disease or cerebellar degeneration . psychological may have emotional pain, sadness and unresolved or unexpressed grief.",
    },
    // 5
    {
      id: 'contractedFurrows-5',
      label: 'Stair Steps CFS',
      values: [
        // 5.1
        {
          id: 'collaretteIntegrity-5.1',
          label:
            ' In cerebral area with two radial furrows required immediate checking of hypertension cause',
        },
        // 5.2
        {
          id: 'collaretteIntegrity-5.2',
          label:
            'with crypts it indicating Epilepsy in the family history or as a result of adverse vaccine reaction, particularly MMR or DPT shots',
        },
      ],
    },
    // 6
    {
      id: 'contractedFurrows-6',
      label: 'Longitudinal CFS',
      details:
        'Indicating vertigo, concentration difficulties, stress related migraine, hearing loss from catarrhal otitis and claustrophobia',
    },
    // 7
    {
      id: 'contractedFurrows-7',
      label: 'Multilevel CFS',
      details:
        'General nerve and psychological sensitivities apparent for organ sectors concerned',
    },
    // 8
    {
      id: 'contractedFurrows-8',
      label: 'Der Tachyphagenring',
      details:
        'Indicates gastro- intestinal spasmodic conditions, resulting from eating too quickly mainly, food intolerance, Tachycardiac responses and person have impatient nature.',
    },
    // 9
    {
      id: 'contractedFurrows-9',
      label: 'Bright Circular Segments',
      details:
        'Family history of cardiac neuroses, hypertension, cholecystitis and flatulence',
    },
    // 10
    {
      id: 'contractedFurrows-10',
      label: 'Lungs CFS',
      details:
        'Present in lung and bronchial topographies indicating tendency to sudden evolution of spasmodic asthma or bronchial episodes',
    },
    // 11
    {
      id: 'contractedFurrows-11',
      label: 'CFS Terminating At a Prominent Closed Lacuna',
      details: ': Distinct Shortage of blood flow to the given area.',
    },
  ],

  pigmentation: [
    // 1.
    {
      id: 'pigmentation-1',
      label: 'Orange',
      details: 'Pancreas Function Tendency',
    },
    {
      id: 'pigmentation-2',
      label: 'Brown',
      details: 'Liver, Gall Bladder Function',
    },
    {
      id: 'pigmentation-3',
      label: 'Yellow',
      details: 'Kidney, Intestinal, Histamine',
    },
    {
      id: 'pigmentation-4',
      label: 'Sandy',
      details: 'Spleen Dysfunction',
    },
    {
      id: 'pigmentation-5',
      label: 'Salmon Pink/Red',
      details: 'Mucus Membrane Compromise',
    },
    {
      id: 'pigmentation-6',
      label: 'Dark Brown',
      details:
        'Liver And Gall Bladder Disturbances, Abdominal Bloating In The Evening.',
    },
    {
      id: 'pigmentation-7',
      label: 'Pathochromia (Psoric Spot)',
      details:
        'immune compromise within the spleen and the MALT, liver stagnation and emotional suppression.',
    },
    {
      id: 'pigmentation-8',
      label: 'Sectoral Heterochromia',
      details:
        'Linked to birth defects or damage within the womb. It is an embryological marking. Sometime appear after spinal cord injuries and dislocations after trauma. Cranial trauma can also be implicated ,in connection with the nerve ganglionic damage. Also indicating the malabsorption of the vital minerals.',
    },
    {
      id: 'pigmentation-9',
      label: 'Central Heterochromia',
      details: 'Denote a toxic colon in recent years,Candida.',
    },
    {
      id: 'pigmentation-10',
      label: 'Straw Yellow',
      values: [
        {
          id: 'pigmentation-10.1',
          label: 'Around Collerete (Need essential fatty acids)',
        },
        {
          id: 'pigmentation-10.2',
          label: 'Need of potassium rich food',
        },
        {
          id: 'pigmentation-10.3',
          label:
            'Indicating potential disturbances with kidneys and large intestine, especially in relation to protein decay and metabolism',
        },
      ],
    },

    {
      id: 'pigmentation-11',
      label: 'White Plaques',
      details:
        'check for oedma, fluctuations in weight ,lymphatic stagnation, hypertension, arthritis and gout. Greater need of Calcium, magnesium, and phosphorus',
    },
    {
      id: 'pigmentation-12',
      label: 'Punctate Pigments:',
      values: [
        {
          id: 'pigmentation-12.1',
          label:
            'it refer to a relationship between blocked parenchyma of the liver and the localized zone. Liver stress which can lead to anaemia, jaundice or hormonal and pituitary alteration. Mostly indicating Haemochromatosis.',
        },
        {
          id: 'pigmentation-12.2',
          label:
            'Accompanied by crypt so there may be the family history of malignancies',
        },
      ],
    },
    {
      id: 'pigmentation-13',
      label: 'Bush Pigment',
      details: 'Liver function and possible immune related complications',
    },
    {
      id: 'pigmentation-14',
      label: 'Waterfall Pigment',
      details: 'Check Renal Endocrine system ,Fluid Balance',
    },
    {
      id: 'pigmentation-15',
      label: 'Guinea Pig Pigment',
      details:
        'Involves the hormonal system with special regards for compromise of the hepatic hormonal clearance pathways.',
    },
    {
      id: 'pigmentation-16',
      label: 'Achromia',
      details: 'It tends to relate to a Dysglycaemia tendency.',
    },
  ],

  miscellaneousIrisSign: [
    // 1.
    {
      id: 'miscellaneousIrisSign-1',
      label: 'Vascularized Vessels',
      details:
        'Topostabile and Usually indicate severe irritation, pain, or stress. Emotionally relates to the suppression of anger and verbal expression',
    },
    {
      id: 'miscellaneousIrisSign-2',
      label: 'Tulip Sign',
      details:
        'Indicating Medical and emotional history of frontal sinus headaches, sinusitis, pressure feeling in the head, dizziness, otitis media, suppressed crying, unresolved grief ,unable to cope with external world pressure, bullying, deep childhood upset and an inability to say no',
    },
    {
      id: 'miscellaneousIrisSign-3',
      label: 'Corkscrew Radials',
      details:
        'Indicating chronic irritation, possible cellular disruption, post surgical adhesion,scar tissue, leg ulcer or nutritional disturbance',
    },
    {
      id: 'miscellaneousIrisSign-4',
      label: 'Fish Hook',
      details:
        'Indicates nervous stomach pain, eroded stomach lining, ulcerations, hyperacidity and a tendency to worry.',
    },
    {
      id: 'miscellaneousIrisSign-5',
      label: 'Neuron Network',
      details: 'Tend to worry and to suffer from anxiety',
    },
    {
      id: 'miscellaneousIrisSign-6',
      label: 'White Radials',
      details: 'Indicate irritation, pain or neuralgia and rheumatic tendency.',
    },
    {
      id: 'miscellaneousIrisSign-7',
      label: 'Rarefaction',
      details: 'Indicates Decreased Activity',
    },
    {
      id: 'miscellaneousIrisSign-8',
      label: 'V Lines',
      details:
        'indicate sensitivity of the vagus nerve and also relevant to sympathetic nerves.',
    },
    {
      id: 'miscellaneousIrisSign-9',
      label: 'Neurohooks',
      details: 'Muscular crams and neuralgia are significant tendencies.',
    },
    {
      id: 'miscellaneousIrisSign-10',
      label: 'Pupillary Zone Neurone Networks',
      details:
        ' Tendency for stress, the receptor sites for peptide hormones can be blocked, overloaded and the hypothalamic pathways could be hyperactive as a result to overcompensate for a lack of positive feedback.',
    },
    {
      id: 'miscellaneousIrisSign-11',
      label: 'Grey Papillary Zone',
      details:
        'Indicate intestinal dysbiosis and functional gastrointestinal disturbance',
    },
    {
      id: 'miscellaneousIrisSign-12',
      label: 'White Intestinal Zone',
      details: 'Tendency to magnesium deficiency and decreased VIP secretion',
    },
    {
      id: 'miscellaneousIrisSign-13',
      label: 'Papillary Shadow Ring',
      details:
        'indicate prominent medical history of the cancers in the family, anaemia,genetic history of scurvy and nutritional requirement of germanium, selenium, zinc, Iron, vitamin C and folic acid.',
    },
    {
      id: 'miscellaneousIrisSign-14',
      label: 'Raised Stroma Mass Over The Collarette ',
      details:
        'Indicating a condition or episode with extreme pain, such as with the passing of renal calculi or peritonitis',
    },
    {
      id: 'miscellaneousIrisSign-15',
      label: 'Combed Hair',
      details:
        'indicating lowered cellular resistance in the tissues, where the fibres occur',
    },
    {
      id: 'miscellaneousIrisSign-16',
      label: 'Lisch Nodules',
      details:
        'There may be decreased progesterone level, poor zinc absorption in the liver (Check Fingernails), low self esteem tendency',
    },
    {
      id: 'miscellaneousIrisSign-17',
      label: 'Lisch nodules 360 on the Collarette',
      details: 'Relate to birth trauma',
    },
  ],

  pupillaryDynamics: [
    // 1.
    {
      id: 'pupillaryDynamics-1',
      label: 'Mydriasis',
      details:
        'The Pupil may not contract with lighting changes, or it will only react very slightly. Possible cerebral haemorrhage, with paralysis of optic nerve -dilation occurs in one eye with a dropping eyelid',
    },
    {
      id: 'pupillaryDynamics-2',
      label: 'Anisocoria',
      details:
        'Indicate pupils of Unequal diameter and size. There may be an history of or actual encephalitis. Hereditary background of meningitis, diphtheria. Some type of the spinal cord injuries. Iatrogenic drug reaction. History of Electric shock. May be a case of Autism.',
    },
    {
      id: 'pupillaryDynamics-3',
      label: 'Miosis',
      details:
        'Constricted Pupil. Normal in elderly People. An history of RA. Usually seen in Sub fertility. The use of nicotine. Vascular Disease .Anxiety. Excess of histamine. The use of Morphine sometime. The over use f barbiturate drugs. Carbon monoxide toxicity. BilateralMiosis is present in advanced Myotonic Dystrophy.',
    },
  ],

  pupilsTonus: [
    // 1
    {
      id: 'pupilsTonus-1',
      label: 'Ellipses',
      values: [
        {
          id: 'pupilsTonus-1.1',
          label: 'Vertical',
          details:
            'Family history of cerebral haemorrhage, stroke, coma or paralysis. Overactivity of the hypothalamus',
        },
        {
          id: 'pupilsTonus-1.2',
          label: 'Horizontal',
          details:
            'indicate underactivity of the hypothalamus and the subsequent neuroendocrinoimmunological pathway',
        },
        {
          id: 'pupilsTonus-1.3',
          label: 'Right Ellipses',
          details:
            'indicate extreme anxiety syndromes which involves neuromuscular spasms and vertigo, right sided paralysis, increased acidity- polyarthritis or cerebellar ataxia',
        },
        {
          id: 'pupilsTonus-1.4',
          label: 'Left Ellipses',
          details:
            'history of left sided paralysis, primary hypertension, left sided cranial problem,headache, labyrinthitis due to injury, sexual concerns and hemiplegic migraines.',
        },
      ],
    },
    // 2
    {
      id: 'pupilsTonus-2',
      label: 'Frontal Diverging',
      details:
        'possible paralysis resulting from cerebral haemorrhage,cardiac neurons, anxiety, muscular spasm; particularly in the chest area with a negative influence on the respiratory system, angina pectoris, sterno cardia problems with thoracic vertebrae 4 and 5 , suppressed anger and tendency to congestion of the liver which can result in hypercholestaemia',
    },
    // 3
    {
      id: 'pupilsTonus-3',
      label: 'Ventral Diverging',
      details:
        'Indicate sacral-lumbar spinal dysfunction with a tendency to cold feet, varicose veins, low libido, fatigue in the legs which it has to stated can all be linked to injury and inflammation of the coccyx. Nutrional need for extra magnesium and potassium in the diet',
    },
  ],

  pupilFlatness: [
    // 1
    {
      id: 'pupilFlatness-1',
      label: 'Frontal Flatness',
      details:
        'Sometime sub fertility due to hypo functioning of pineal gland as well suggesting dysfunction of other endocrine glands like hypothalamus, pituitary, or pineal. Emotionally suppressed anger, disturb with grief , guilty feelings, self doubt.',
    },
    // 2
    {
      id: 'pupilFlatness-2',
      label: 'Ventral Flatness',
      values: [
        // 2.1
        {
          id: 'pupilFlatness-2.1',
          label:
            'Indicate spinal problems from the third lumbar vertebra (L3) through the sacrum and coccyx with resulting poor circulation to the legs, osteoarthritis especially of the hip and knees, pelvic problems, varicose veins, odema and migraine headaches. There may be possibility of prolapse of anus. Nutritional requirements needs potassium, magnesium, vitamin E and essential fatty acids.',
        },
        // 2.2
        {
          id: 'pupilFlatness-2.2',
          label: 'The possibility of Uterus Prolapse',
        },
      ],
    },
    // 3
    {
      id: 'pupilFlatness-3',
      label: 'Medial Nasal Flatness',
      details:
        'Indicate tendencies to breathing difficulties, thyroid gland imbalances (usually hypothyroidism), muscular tension and spinal changes at C6- C7 and through the thoracic vertebrae from T1 to T7. There may be the condition of Arrhythmia and Angina Pectoris.',
    },
    // 4
    {
      id: 'pupilFlatness-4',
      label: 'Lateral Temporal Flatness',
      details:
        'Indicate predisposition to circulatory problems, nervous breathing such as asthma, emotional heart problem, breast problems such as mastalgia and fibrocystic disease. Sometime concern to Crohn’s disease, duodenitis and malabsorption syndrome.',
    },
    // 5
    {
      id: 'pupilFlatness-5',
      label: 'Superior Nasal Flatness',
      details:
        'Indicate neuromuscular and spinal tensions at C3 to C7. Physical tendencies to visual disturbances,cranial injuries, cervical muscular tension, acne linked to liver congestion.',
    },
    // 6
    {
      id: 'pupilFlatness-6',
      label: 'Superior Temporal Flatness',
      details:
        'Indicating Hearing and balance problem such as vertigo or tinnitus, cerebellar degeneration, muscular tension in neck and shoulders, spinal subluxations between C2 and C7. Emotionally feel confusion, frustration, ability to touch and wild character swings from indifference to enthusiasm.',
    },
    // 7
    {
      id: 'pupilFlatness-7',
      label: 'Inferior Temporal Flatness',
      values: [
        {
          id: 'pupilFlatness-7.1',
          label:
            'Indicate neck and shoulder tension as a result of an injury such as whiplash. There may be the liver and gall bladder problems plus pancreatic concerns such as dysglycemia or hypoglycaemia tendencies. There may be thoracic vertebrae irritations from T8 to T12 and Lumbar irritations from L1 to L2 .',
        },
        {
          id: 'pupilFlatness-7.2',
          label:
            'In females Amenorrhoea, Menorrhagia or Polycystic ovary syndrome may be possible.',
        },
      ],
    },
    // 8
    {
      id: 'pupilFlatness-8',
      label: 'Inferior Nasal Flatness',
      values: [
        // 8.1
        {
          id: 'pupilFlatness-8.1',
          label:
            'Predisposition to and history of sacral, lumbar and coccyx injuries leading to leg problems, sciatica, osteoporosis or rheumatism. There may be renal calculi, arthritis, gout, hypertension, nephritis, polycystic kidneys, and urinary infection. Nutrition absorption impaired of zinc and magnesium. Emotionally person feel guilt, self-criticism, withholding emotional side due to fear of failure, hyperactivity, living life for other people and not for oneself, impatience.',
        },
        // 8.2
        {
          id: 'pupilFlatness-8.2',
          label: 'In male there may be the prostate or testicular changes.',
        },
        // 8.3
        {
          id: 'pupilFlatness-8.3',
          label:
            'In females we can observe the correlation with uterine problem leading to difficult pregnancies, fibroids, inflammation, post-surgical adhesion, endometriosis, or cervical dysplasia.',
        },
      ],
    },
  ],

  conjunctivalAndCornealSign: [
    {
      id: 'conjunctivalAndCornealSign-1',
      label: 'Corneal Arcus',
      details: 'It is sign due to aging or high cholesterol',
    },
    {
      id: 'conjunctivalAndCornealSign-2',
      label: 'Porcelain Vessel',
      details:
        'It is indicative of endocrine alteration, in relation to diabetes mellitus',
    },
    {
      id: 'conjunctivalAndCornealSign-3',
      label: 'Meandering Vessels',
      details:
        'It is indicative of liver congestion, portal vein congestion, possible haemorrhoides and the development of other varicosities',
    },
    {
      id: 'conjunctivalAndCornealSign-4',
      label: 'Blue Sclera Plaques',
      details:
        'The blue plaque in the sclera indicates an area of calcification - can be bilateral. sometime due to iatrogenic reaction or associated with aging.',
    },
    {
      id: 'conjunctivalAndCornealSign-5',
      label: 'Honey Comb',
      values: [
        {
          id: 'conjunctivalAndCornealSign-5.1',
          label: 'Diabetes Mellitus when honey comb is thickened and swollen',
        },
        {
          id: 'conjunctivalAndCornealSign-5.2',
          label: 'If multiple bouts of chicken pox experienced',
        },
      ],
    },
    {
      id: 'conjunctivalAndCornealSign-6',
      label: 'Corkscrew Vessels',
      details:
        'indicate weakening of the vessels and general circulation. They are precursor marker, before more serious arterial problems begin to develop',
    },
    {
      id: 'conjunctivalAndCornealSign-7',
      label: 'Broken Vessels',
      details:
        'It indicates atherosclerosis and high cholesterol accumulations, which could manifest in the arteries, liver, heart or gall bladder.',
      values: [
        {
          id: 'conjunctivalAndCornealSign-7.1',
          label: 'Yellow Sclera',
          details:
            'Indicative of Liver disturbances and could be linked with fatty deposits or local injury to the eye. Jaundice could be the medical history.',
        },
      ],
    },
  ],

  transversal: [
    // 1
    {
      id: 'transversal-1',
      label: 'Antlered Transversals',
      details:
        ' Indicating female urogenital areas and tendency to polyps, cysts, fibroids, adhesions, salpingitis and endometriosis.',
    },
    {
      id: 'transversal-2',
      label: 'Fork Transversal',
      values: [
        {
          id: 'transversal-2.1',
          label:
            'when toward the collarette a state of abdominal venous congestion or abdominal plethora is indicated.',
        },
        {
          id: 'transversal-2.2',
          label:
            'If toward ciliary edge it indicates lymphatic and venous congestion of the organ concerned.',
        },
      ],
    },

    {
      id: 'transversal-3',
      label: 'Tent Transversal',
      details:
        'Often found in lower back and hip areas. It indicates a predisposition to degeneration of the hip, hip replacement, osteopenia, or osteoporosis. Emotionally people want to live life for other people and not for themselves.',
    },
    {
      id: 'transversal-4',
      label: 'Root Transversal',
      values: [
        {
          id: 'transversal-4.1',
          label:
            'Indicate obstruction and congestion. often detected in hepatic and splenic fields where they can indicate allergies, anaemia, inflammations and vaccination reaction. Sometime asthma and bronchitis.',
        },
        {
          id: 'transversal-4.2',
          label:
            'Vascularised Root transversal indicating urgent requirement to double-check for malignant development or inflammatory development',
        },
      ],
    },
    {
      id: 'transversal-5',
      label: 'Sack Transversal',
      details: 'Indicating degenerative or gangrenous condition',
    },
    {
      id: 'transversal-6',
      label: 'X Transversals ',
      details: 'Indicating ulcerations or bone marrow changes.',
    },
    {
      id: 'transversal-7',
      label: 'Wedge Shaped ',
      details:
        'often vascularised ,this sign indicates chronic inflammation and impaired immune responses, possible malignancies or arthritic developments.',
    },
    {
      id: 'transversal-8',
      label: 'Ulcerative colitis',
      values: [
        {
          id: 'transversal-8.1',
          label: 'Wedge Shaped ',
        },
        {
          id: 'transversal-8.2',
          label: 'Endometriosis',
        },
      ],
    },
    {
      id: 'transversal-9',
      label: 'Running Over Cryptoid Formation',
      details:
        'Indicating chronic fluid retention, history of nephritis or infection of the urinary system.',
    },
    {
      id: 'transversal-10',
      label: 'Fountain Transversal',
      details:
        'Indicating a tendency to peripheral stagnation and blockage of localized lymph nodes. Patient may have the constipation',
    },
    {
      id: 'transversal-11',
      label: 'Intestinal',
      details: 'Indicating Autoimmune bowel problems like ulcerative colitis',
    },
    {
      id: 'transversal-12',
      label: 'Roof Transversal',
      details:
        'who experiencing the fracture hip, also due to osteopenia sometime.',
    },
    {
      id: 'transversal-13',
      label: 'Deep And Multi Branched',
      details:
        'Compromise endocrinological function with emphasis on the adrenal glands and cortisol secretion.',
    },
    {
      id: 'transversal-14',
      label: 'Spleen Transversal',
      details:
        'Indicating dysfunction of the spleen, tendency to disruption of the response of the RES and a history of splenomegaly or enlarged spleen.',
    },
    {
      id: 'transversal-15',
      label: 'Hepatic',
      details: 'Indicating the liver pathology',
    },
    {
      id: 'transversal-16',
      label: 'Meander',
      details: 'Linked to osteomyelitis, osteoporosis and arthritis',
    },
    {
      id: 'transversal-17',
      label: 'Curved Transversals',
      details: 'It is Indicative of fibrosis or hardening of the tissues.',
    },
  ],

  presenceOfRingsAndStructures: [
    // 1
    {
      id: 'presenceOfRingsAndStructures-1',
      label: 'Stomach Ring',
      values: [
        {
          id: 'presenceOfRingsAndStructures-1.1',
          label: 'Darkened',
          details:
            'Indicating diminished gastric activity due to low hydrochloric acid and enzymatic the activity levels.',
        },
        {
          id: 'presenceOfRingsAndStructures-1.2',
          label: 'Lightened',
          details: 'Indicate excessive acidity within the system, acidosis',
        },
      ],
    },
    {
      id: 'presenceOfRingsAndStructures-2',
      label: 'Scurf Ring',
      details:
        'The scurf Ring relates to the condition of skin and peripheral lymph, such as cutaneous associated Lymphoid Tissue (CALT).',
    },
    {
      id: 'presenceOfRingsAndStructures-3',
      label: 'Healing Lines',
      details:
        'It indicate that old ,damaged tissue is being replaced with new ,healthy tissues.',
    },
    {
      id: 'presenceOfRingsAndStructures-4',
      label: 'Lymphatic Rosary',
      details: 'Indicating heavy lymphatic system congestion.',
    },
    {
      id: 'presenceOfRingsAndStructures-5',
      label: 'Sodium Ring',
      details:
        'Sodium or cholesterol ring indicate heavy sodium sodium intake and high blood cholesterol levels.',
    },
    {
      id: 'presenceOfRingsAndStructures-6',
      label: 'Circulatory Ring',
      details:
        'Indicating that conditions are right for varicose veins to develop.',
    },
    {
      id: 'presenceOfRingsAndStructures-7',
      label: ' Arcus senile',
      details:
        'A major circulatory indicator is the arcus senile, or are of old age .It indicates compromised circulation to the brain.',
    },
  ],

  theAxis: [
    // 1
    {
      id: 'theAxis-1',
      label:
        'Stress axis:- Indicates a strong disposition for the individual to experience adverse reactions and por adaptability to stress',
    },
    {
      id: 'theAxis-2',
      label:
        'Immune axis:- It highlights the dispositional nature of a person’s immune system to compromise and alterations in reactivity.',
    },
    {
      id: 'theAxis-3',
      label:
        'Thyroid axis:- Its presence increases the susceptibility of thee patient to thyroid disease and highlights the genetic inheritance of that tendency.',
    },
  ],
};

export default fields;
