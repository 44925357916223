import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import { BsBookmarkDash } from 'react-icons/bs';
import { MdDashboard } from 'react-icons/md';
import { AiOutlineDashboard, AiOutlineUser } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';

import Sidebar from './Sidebar';
import { Dropdown } from '../elements';

const adminSideBar = [
  {
    id: '1',
    name: 'Dashboard',
    href: '/dashboard',
    icon: <AiOutlineDashboard />,
    hasSubMenu: false,
  },
  {
    id: '2',
    name: 'Manage Centers',
    icon: <MdDashboard />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 2.1,
        name: 'Add Center',
        href: '/center/add',
      },
      {
        id: 2.2,
        name: 'View All',
        href: '/center/all',
      },
    ],
  },
  {
    id: '3',
    name: 'Manage Agency',
    icon: <MdDashboard />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 3.1,
        name: 'Add Agency',
        href: '/agency/add',
      },
      {
        id: 3.2,
        name: 'View All',
        href: '/agency/all',
      },
    ],
  },
  {
    id: '4',
    name: 'Manage Packages',
    icon: <BsBookmarkDash />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 4.1,
        name: 'Add Package',
        href: '/package/add',
      },
      {
        id: 4.2,
        name: 'View Packages',
        href: '/package/all',
      },
    ],
  },
  {
    id: '5',
    name: 'Manage Customers',
    icon: <AiOutlineUser />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 5.1,
        name: 'Add Customer',
        href: '/customer/add',
      },
      {
        id: 5.2,
        name: 'All Customers',
        href: '/customer/all',
      },
    ],
  },
  {
    id: '6',
    name: 'Manage Reports',
    icon: <HiOutlineDocumentReport />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 3.1,
        name: 'Pending Reports',
        href: '/reports/pending',
      },
      {
        id: 6.2,
        name: 'Send Reports',
        href: '/reports/send',
      },
      {
        id: 6.3,
        name: 'Dispatched Reports',
        href: '/reports/dispatched',
      },
    ],
  },
];

const agencySideBar = [
  {
    id: '1',
    name: 'Dashboard',
    href: '/dashboard',
    icon: <AiOutlineDashboard />,
    hasSubMenu: false,
  },
  {
    id: '2',
    name: 'Manage Centers',
    icon: <MdDashboard />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 2.1,
        name: 'Add Center',
        href: '/center/add',
      },
      {
        id: 2.2,
        name: 'View All',
        href: '/center/all',
      },
    ],
  },
  {
    id: '3',
    name: 'Manage Customers',
    icon: <AiOutlineUser />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 3.1,
        name: 'Add Customer',
        href: '/customer/add',
      },
      {
        id: 3.2,
        name: 'All Customers',
        href: '/customer/all',
      },
    ],
  },
  {
    id: '4',
    name: 'Manage Reports',
    icon: <HiOutlineDocumentReport />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 4.1,
        name: 'Center Reports',
        href: '/reports/center/send',
      },
      {
        id: 4.2,
        name: ' Pending Reports',
        href: '/reports/pending',
      },
      {
        id: 4.3,
        name: ' Received Reports',
        href: '/reports/received',
      },
      {
        id: 4.4,
        name: 'Dispatched Reports',
        href: '/reports/dispatched',
      },
    ],
  },
];

const centerSideBar = [
  {
    id: '1',
    name: 'Dashboard',
    href: '/dashboard',
    icon: <AiOutlineDashboard />,
    hasSubMenu: false,
  },
  {
    id: '2',
    name: 'Manage Customers',
    icon: <AiOutlineUser />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 2.1,
        name: 'Add Customer',
        href: '/customer/add',
      },
      {
        id: 2.2,
        name: 'All Customers',
        href: '/customer/all',
      },
    ],
  },
  {
    id: '3',
    name: 'Manage Reports',
    icon: <HiOutlineDocumentReport />,
    hasSubMenu: true,
    subMenu: [
      {
        id: 3.1,
        name: 'Pending Reports',
        href: '/reports/pending',
      },
      {
        id: 3.2,
        name: 'Received Reports',
        href: '/reports/received',
      },
    ],
  },
];

const DashboardLayout = ({ children }) => {
  const [sidebarItems, setSidebarItems] = useState([]);
  const user = useStoreState((state) => state.user.data);

  useEffect(() => {
    if (user.type === 'admin') {
      setSidebarItems([...adminSideBar]);
    } else if (user.type === 'agency') {
      setSidebarItems([...agencySideBar]);
    } else if (user.type === 'center') {
      setSidebarItems([...centerSideBar]);
    }
  }, []);

  return (
    <div>
      <Sidebar sidebarItems={sidebarItems} />
      <div className="ml-56 md:w-auto">
        <header className="w-full items-center bg-white py-2 px-6 hidden sm:flex border-b-4	">
          <div className="w-1/2" />
          <div className="w-1/2 flex justify-end  items-center">
            <p className="text-xl text-green-600	">
              Hi,
              {user.name}
            </p>
            <Dropdown />
          </div>
        </header>
        <div className="pb-10">
          <div className="md:px-1 mx-auto w-full mt-10 ">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
