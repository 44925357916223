import React from 'react';

const CustomerDetails = ({ customer }) => {
  return (
    <div className="mt-80">
      <div>
        <p className="mb-2">
          <span className="font-bold mr-2">NAME:</span>
          {customer.name}
        </p>
        <p className="mb-2">
          <span className="font-bold mr-2">SEX:</span> {customer.gender}
        </p>
      </div>
      <div>
        <p className="mb-2">
          <span className="font-bold mr-2">AGE:</span>
          {customer.age}
        </p>
      </div>
      {customer.familyHistory && (
        <div>
          <p className="mb-2">
            <span className="font-bold mr-2">
              ANY HISTORY OF DISEASES IN FAMILY
            </span>
          </p>
          <p className="mb-2">{customer.familyHistory}</p>
        </div>
      )}

      {customer.symptomsAndSufferings && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">
              PRESENT ANY SYMPTOMS & SUFFERINGS
            </span>
          </p>
          <p className="mb-2">{customer.symptomsAndSufferings}</p>
        </div>
      )}

      {customer.treatmentHistory && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">
              History of any treatment taken for long & Diseases
            </span>
          </p>
          <p className="mb-2">{customer.treatmentHistory}</p>
        </div>
      )}

      {customer.surgicalOperationsHistory && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">
              ANY HISTORY OF SURGICAL OPERATIONS
            </span>
          </p>
          <p className="mb-2">{customer.surgicalOperationsHistory}</p>
        </div>
      )}

      {customer.gynecologyProblems && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">
              HISTORY OF GENETIC PROBLEMS ( in females )
            </span>
          </p>
          <p className="mb-2">{customer.gynecologyProblems}</p>
        </div>
      )}

      {customer.reproductiveProblems && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">
              History of Reproductive Problems ? ( in Males )
            </span>
          </p>
          <p className="mb-2">{customer.reproductiveProblems}</p>
        </div>
      )}

      {customer.specificProblems && customer.specificProblems.length > 0 && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">ANY SPECIFIC PROBLEM</span>
          </p>
          {customer.specificProblems.map((item) => (
            <p className="mb-2">*{item}</p>
          ))}
        </div>
      )}
      {customer.details && (
        <div>
          <p className="mb-1">
            <span className="font-bold mr-2">ANY OTHER INFORMATION</span>
          </p>
          <p className="mb-2">{customer.details}</p>
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
