import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiSettings } from 'react-icons/fi';
import { useStoreActions } from 'easy-peasy';
import { Link } from 'react-router-dom';

const Dropdown = () => {
  const toggleLoggedIn = useStoreActions(
    (actions) => actions.isLoggedIn.toggle,
  );
  const updateUser = useStoreActions((actions) => actions.user.update);
  return (
    <Menu as="div" className="ml-4 relative inline-block text-left">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              <FiSettings className="w-8 h-8" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-300"
            enterFrom="transform opacity-0 scale-90"
            enterTo="transform opacity-100 scale-300"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1">
                <Menu.Item key="1">
                  <Link
                    to="/profile"
                    className="bg-gray-100 text-gray-900 block px-4 py-2 text-xl account-link hover:text-white rounded-md m-1  transition duration-500 ease-in-out transform"
                  >
                    ACCOUNT
                  </Link>
                </Menu.Item>
                <Menu.Item key="1">
                  <div
                    className="cursor-pointer bg-gray-100 text-gray-900 block px-4 py-2 hover:text-white text-xl account-link rounded-md m-1 transition duration-500 ease-in-out transform"
                    onClick={() => {
                      updateUser({});
                      toggleLoggedIn(false);
                      if (window) {
                        window.localStorage.clear();
                      }
                    }}
                    role="button"
                    aria-hidden="true"
                  >
                    SIGN OUT
                  </div>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
export default Dropdown;
