import { createGlobalStyle } from 'styled-components';

const mainBrandColor = '#28c08e';
const darkShades = '#0a6f4d';
const lightAccent = '#9fe3cd';

const theme = {
  mainBrandColor,
  darkShades,
  lightAccent,
  primaryColor: '#28c08e',
  secondaryColor: '#2876C4',
};

export default theme;

const GlobalStyle = createGlobalStyle`

  body {
    margin: 0;
    padding: 0;
    font-family: poppins , sans-serif;
  }
  .bg-sidebar { background: ${theme.mainBrandColor}; }
  .cta-btn { color: ${theme.mainBrandColor}; }
  .active-nav-link { background: ${theme.darkShades}; }
  .nav-item:hover { background: ${theme.darkShades}; }
  .account-link:hover { background: ${theme.mainBrandColor}; }
`;

export { GlobalStyle };
