/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';

import { BsBookmarkDash } from 'react-icons/bs';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Select({ label, data, onChange }) {
  const [selected, setSelected] = useState(data[0]);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(data[0]);
  }, [data]);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className="block uppercase tracking-wide text-gray-700 text-base font-bold mt-6 mb-4">
            {label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm">
              <span className="flex items-center">
                <span className="block truncate">
                  {selected && selected.name ? selected.name : ''}
                </span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <BsBookmarkDash />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {data.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={
                      ({ active }) =>
                        classNames(
                          active ? 'text-white bg-green-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9',
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    value={item}
                  >
                    {({ selectedItem, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selectedItem ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {item.name}
                          </span>
                        </div>

                        {selectedItem ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-green-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <BsBookmarkDash />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
