import { useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { AgencyForm } from '../../components/forms';
import { Loading } from '../../components/elements';

const allPackages = gql`
  query {
    allPackages {
      id
      name
      reports
    }
  }
`;

const addAgencyMutation = gql`
  mutation addAgency($input: RegisterInput) {
    register(input: $input) {
      user {
        userId
        id
      }
    }
  }
`;

const AddCenter = () => {
  const { data, loading } = useQuery(allPackages, {
    fetchPolicy: 'cache-and-network',
  });

  const [executeMutation, { data: data1, loading: loading1, error }] =
    useMutation(addAgencyMutation);

  const packages = data && data.allPackages ? data.allPackages : [];

  const handleSubmit = (val) => {
    executeMutation({
      variables: {
        input: {
          name: val.name,
          password: val.password,
          email: val.email,
          telephone: val.phoneNumber,
          type: 'agency',
          package: val.package,
          status: 'active',
          address: {
            country: val.country,
            state: val.state,
            city: val.city,
            address: val.address,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (error) {
      swal('Oops', 'Agency can not be created', 'error');
    }
    if (data1) {
      swal('Congrats', 'Agency Created', 'success');
    }
  }, [data1, error]);

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16 text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {loading1 && <Loading />}
            {loading && <Loading />}
            {packages && (
              <AgencyForm packages={packages} handleSubmit={handleSubmit} />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AddCenter;
