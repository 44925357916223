import React from 'react';

const SearchBar = () => (
  <div className="container  flex">
    <div className="relative">
      <input
        type="text"
        className="h-14 w-96 pr-8 pl-5 rounded z-0 shadow outline-none"
        placeholder="Search anything..."
      />
      <div className="absolute top-4 right-3">
        <i className="fa fa-search text-gray-400 z-20 hover:text-gray-500" />
      </div>
    </div>
  </div>
);

export default SearchBar;
