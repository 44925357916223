import React, { useEffect } from 'react';
import styled from 'styled-components';

import { gql, useMutation } from '@apollo/client';
import { useStoreActions } from 'easy-peasy';
import swal from 'sweetalert';

import Seo from '../components/Seo';
import Layout from '../components/Layout';
import LoginForm from '../components/forms/LoginForm';
import { Loading } from '../components/elements';

const mutation = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      jwt
      user {
        id
        name
        telephone
        email
        status
        type
        createdBy {
          id
          type
        }
      }
    }
  }
`;

const Section = styled.div`
  .bg-gray-100 {
    margin: 0 auto;
  }
`;

const LogIn = ({ history }) => {
  const [executeMutation, { data, error, loading }] = useMutation(mutation);
  const toggleLoggedIn = useStoreActions(
    (actions) => actions.isLoggedIn.toggle,
  );
  const updateUser = useStoreActions((actions) => actions.user.update);
  useEffect(() => {
    if (error) swal('Oops', 'Invalid username or password', 'error');
  }, [error]);

  useEffect(() => {
    if (data && data.login) {
      const { jwt, user } = data.login;
      if (user.status !== 'active') {
        swal(
          'Oops',
          'your account is suspended by admin please contact',
          'error',
        );
      }
      window.localStorage.setItem('token', jwt);
      toggleLoggedIn(true);
      updateUser(user);
      setTimeout(() => {
        history.push('/dashboard');
      }, 1000);
    }
  }, [data]);

  return (
    <Layout>
      <Seo title="Log In" description="Smart Home devices" />
      <Section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap items-center">
          <div className="lg:w-3/6 md:w-1/2 bg-gray-100 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 ">
            <h2 className="text-gray-900 text-lg font-medium title-font mb-5">
              Log In
            </h2>
            <LoginForm
              handleSubmit={(val) =>
                executeMutation({
                  variables: {
                    ...val,
                  },
                })
              }
            />
            {loading && <Loading />}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default LogIn;
