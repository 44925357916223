import sanityClient from '@sanity/client';
import config from './config';

export default sanityClient({
  // Find your project ID and dataset in sanity.json in your studio project
  projectId: config.sanityId,
  dataset: 'production',
  apiVersion: '2021-03-25',
  useCdn: true,
  token: config.sanityToken,
  // useCdn == true gives fast, cheap responses using a globally distributed cache.
  // Set this to false if your application require the freshest possible
  // data always (potentially slightly slower and a bit more expensive).
});
