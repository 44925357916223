import React from 'react';
import { View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import DetailItem from './DetailItem';

const Details = ({ report, data }) => {
  return (
    <View style={{ marginBottom: 30 }}>
      {data &&
        data.map((item) => {
          if (!isEmpty(item.values)) {
            return <DetailItem key={Math.random()} data={item} />;
          }
          return <View key={Math.random()} />;
        })}
      {report && report.details ? (
        <DetailItem
          key={Math.random()}
          data={{
            label: 'Extra Information',
            subTitle: report.details,
          }}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

export default Details;
