import Layout from '../components/Layout';

import HomeHero from '../components/HomeHero';
import Card from '../components/Card';
import Iridology from '../components/Iridology';
import AboutUs from '../components/AboutUs';
import Team from '../components/Team';
// import Article from '../components/Article';
import Services from '../components/Services';
import ContactUs from '../components/ContactUs';

const features1 = [
  {
    id: 1,
    description: 'The Primary Nutritional Need of the body',
  },
  {
    id: 2,
    description:
      'The Inhrrent Strength or Weakness of Organs, Glands and Tissues',
  },
  {
    id: 3,
    description: 'Consitutional Strength Weakness.',
  },
  {
    id: 4,
    description:
      'Which Organs are in the Gretest Need of Repair and Rebuilding',
  },
  {
    id: 5,
    description:
      'The Inhrrent Strength or Weakness of Organs, Glands and Tissues',
  },
  {
    id: 6,
    description: 'Consitutional Strength Weakness.',
  },
];

const features2 = [
  {
    id: 1,
    description: 'Blood Pressure Level',
  },
  {
    id: 2,
    description: 'Blood Sugar Level',
  },
  {
    id: 3,
    description: 'Other Specific Diagnostic Finding and Laboratory Test Result',
  },
  {
    id: 4,
    description:
      'Which Specific Medications or Drugs an Individual is Using or Has Used in the Past',
  },
];

function Index() {
  return (
    <Layout>
      <HomeHero />
      <Card features1={features1} features2={features2} />
      <AboutUs />
      <Iridology />
      <Team />
      <Services />
      {/* <Article /> */}
      <ContactUs />
    </Layout>
  );
}

export default Index;
