import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  input[type='radio'] + label span {
    transition: background 0.05s, transform 0.05s;
  }

  input[type='radio'] + label span:hover,
  input[type='radio'] + label:hover span {
    transform: scale(1.2);
  }

  input[type='radio']:checked + label span {
    background-color: #3490dc; //bg-blue
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type='radio']:checked + label {
    color: #3490dc; //text-blue
  }
`;

const RadioInput = ({ name, label, value, onClick, hasChecked = false }) => {
  const [checked, setChecked] = useState(hasChecked);

  return (
    <Container>
      {/* <label className="inline-flex items-center">
        <input
          type="radio"
          className="form-radio"
          name="accountType"
          value="personal"
        />
        <span className="ml-2">Personal</span>
      </label> */}
      <Container className="flex items-center mb-2">
        <input
          type="radio"
          className="hidden form-radio"
          name={name}
          value={value}
          checked={hasChecked}
          onChange={() => {
            setChecked(!checked);
            if (value) {
              onClick(value);
            }
          }}
        />

        <label
          htmlFor="radio"
          className="flex  cursor-pointer text-lg"
          onClick={() => {
            setChecked(!checked);
            if (value) {
              onClick(value);
            }
          }}
          aria-hidden="true"
        >
          <span className="w-6 h-6 inline-block mr-2 rounded-full border border-black " />
          {label}
        </label>
      </Container>
    </Container>
  );
};

export default RadioInput;
