import { gql, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import Seo from '../../components/Seo';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { CenterTable } from '../../components/tables';
import { EmptyState } from '../../components/elements';

const allCenterQuery = gql`
  query allUsers {
    allUsers(type: center) {
      id
      userId
      email
      name
      status
      telephone
      reportsLimit
      address {
        country
        state
        city
        address
      }
    }
  }
`;

const AllCenter = () => {
  const { data } = useQuery(allCenterQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const allCenter = data && data.allUsers ? data.allUsers : [];

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <h1 className="text-4xl text-center text-green-600 border-b-2 pb-2	m-5">
        All Centers
      </h1>
      {isEmpty(allCenter) && <EmptyState subTitle="Please add a Center" />}
      {/* <div className="w-1/2 pb-4 mt-10 md:px-0 ml-2">
        <SearchBar />
      </div> */}

      {!isEmpty(allCenter) && (
        <div className="w-full pb-4 md:px-0 md-8 mb-16 b text-gray-800 leading-normal">
          <CenterTable data={allCenter} />
        </div>
      )}
    </DashboardLayout>
  );
};

export default AllCenter;
