// import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
// import swal from 'sweetalert';
import Seo from '../../components/Seo';
import { Loading } from '../../components/elements';

import DashboardLayout from '../../components/dashboard/DashboardLayout';
import { PackageForm } from '../../components/forms';

const singlePackageQuery = gql`
  query singlePackage($id: ID!) {
    singlePackage(id: $id) {
      id
      name
      reports
    }
  }
`;

const EditPackage = () => {
  const { id } = useParams();

  const { data, loading } = useQuery(singlePackageQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const singlePackage = data && data.singlePackage ? data.singlePackage : {};

  return (
    <DashboardLayout>
      <Seo title="Add Agency" description="Iris Center Add Agency" />
      <div className="w-full pb-4 md:px-0 md-8 mb-16  text-gray-800 leading-normal">
        <div className="grid grid-cols-6 gap-4 ">
          <div className="col-start-2 col-span-4">
            {loading && <Loading />}
            {singlePackage?.name && (
              <PackageForm initialValues={singlePackage} />
            )}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default EditPackage;
