import React, { useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useStoreState } from 'easy-peasy';

import { TableHead } from '../elements';

const tableHeadData = [
  { name: 'S.NO.', width: 'w-1' },
  { name: 'Agency', isVisible: 'admin' },
  { name: 'Center' },
  { name: 'Customer ID', width: 'w-1/5' },
  { name: 'Name' },
  { name: 'Age', width: 'w-1/12' },
  { name: 'Gender', width: 'w-48' },
  { name: 'Date' },
  // { name: 'Status' },
  { name: 'More' },
];

const Tr = styled.tr``;

const ReportsTable = ({ data, handleUpdateReport, params, handleDelete }) => {
  const user = useStoreState((state) => state.user.data);

  const [show, setShow] = useState(null);

  const groupedClass =
    'bg-gray-100 text-gray-900 block px-4 py-2 cursor-pointer text-xs account-link hover:text-white rounded-md m-1 transition duration-500 ease-in-out transform';

  return (
    <div className="shadow-xl	 px-1 md:px-1 pt-4 md:pt-7 pb-5 overflow-y-auto">
      <table className="w-full border-collapse border  ">
        <TableHead data={tableHeadData} user={user} />
        <tbody className="w-full">
          {data.map((item, index) => {
            if (
              user.type === 'center' &&
              params === 'pending' &&
              item.status === 'success' &&
              item.hasAgencySendApproval === true
            ) {
              return null;
            }

            return (
              <Tr
                className="h-20 text-sm leading-4	 text-gray-800 bg-white hover:bg-gray-100 	divide-x divide-gray-100 border-2"
                key={item.id}
              >
                <td className="p-2">{index + 1}</td>
                {user.type === 'admin' && (
                  <td className="p-2">
                    {(item.agency && item.agency.name) || 'Admin'}
                  </td>
                )}

                <td className="p-2">{item.initiatedBy.name || ''}</td>
                <td className="p-2">{item.customer.customerId || ''}</td>
                <td className="p-2">
                  {(item.customer && item.customer.name) || ''}
                </td>
                <td className="p-2">{item.customer.age || ''}</td>
                <td className="p-2 capitalize">{item.customer.gender || ''}</td>
                <td className="p-2">
                  {dayjs(item.createdAt).format('DD/MM/YYYY')}
                </td>
                {/* <td className="p-2 justify-center">
                <Tag status={item.status} />
              </td> */}
                {/* <td className="p-2">
                <Tag status="active" />
              </td> */}
                <td className="p-2 2xl:px-0">
                  {show === index ? (
                    <button
                      type="button"
                      onClick={() => setShow(null)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setShow(index)}
                      className="focus:outline-none pl-1 text-xl"
                    >
                      <BiDotsHorizontalRounded />
                    </button>
                  )}
                  {show === index && (
                    <div
                      className="dropdown-content bg-white shadow w-36 absolute z-30 right-0 mr-6"
                      onClick={() => setShow(null)}
                    >
                      {user.type === 'admin' && (
                        <Link
                          to={`/report/make/${item.id}`}
                          className={groupedClass}
                          // onClick={() => handleCreateReport(item)}
                        >
                          <p>
                            {params === 'pending'
                              ? 'Make Report'
                              : 'Edit Report'}
                          </p>
                        </Link>
                      )}
                      {params !== 'pending' && item.status === 'success' && (
                        <Link
                          to={`/report/pdf/${item.id}`}
                          className={groupedClass}
                          target="_blank"
                          // onClick={() => handleCreateReport(item)}
                        >
                          <p>Generate PDF</p>
                        </Link>
                      )}
                      {user.type === 'agency' &&
                        item.status === 'pending' &&
                        !item.hasAgencyApproval && (
                          <div
                            className={groupedClass}
                            onClick={() =>
                              handleUpdateReport({
                                id: item.id,
                                status: 'pending',
                                customer: item.customer.id,
                                hasAgencyApproval: true,
                              })
                            }
                          >
                            <p>Send to Admin</p>
                          </div>
                        )}
                      {user.type === 'agency' &&
                        item.status === 'success' &&
                        item.hasAgencyApproval &&
                        !item.hasAgencySendApproval && (
                          <div
                            className={groupedClass}
                            onClick={() =>
                              handleUpdateReport({
                                id: item.id,
                                status: 'success',
                                customer: item.customer.id,
                                hasAgencySendApproval: true,
                              })
                            }
                          >
                            <p>Send to Center</p>
                          </div>
                        )}
                      {user.type === 'admin' && item.status === 'draft' && (
                        <div
                          className={groupedClass}
                          onClick={() =>
                            handleUpdateReport({
                              id: item.id,
                              status: 'success',
                              customer: item.customer.id,
                            })
                          }
                        >
                          <p>Send to Agency/Center</p>
                        </div>
                      )}
                      {user.type === 'admin' && item.status === 'pending' && (
                        <div
                          className={groupedClass}
                          onClick={() => handleDelete(item.id)}
                        >
                          <p>Delete</p>
                        </div>
                      )}
                    </div>
                  )}
                </td>
              </Tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ReportsTable;
