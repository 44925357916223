import { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import Swal from 'sweetalert';

import Seo from '../components/Seo';
import DashboardLayout from '../components/dashboard/DashboardLayout';

const Profile = () => {
  const isLoggedIn = useStoreState((state) => state.isLoggedIn.value);

  useEffect(() => {
    if (!isLoggedIn) {
      Swal('user not Authorized');
    }
  }, [isLoggedIn]);

  console.log(isLoggedIn, 'isLoggedIn');

  return (
    <DashboardLayout>
      <Seo title="Profile" description="Iris Center Profile Dashboard" />
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
        Profile
      </div>
    </DashboardLayout>
  );
};

export default Profile;
