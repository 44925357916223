import { useStoreState } from 'easy-peasy';

const TableHead = ({ data }) => {
  const user = useStoreState((state) => state.user.data);

  return (
    <thead>
      <tr className="w-full text-base leading-none text-gray-800 border-b-4 border-fuchsia-600">
        {data.map((item) => {
          if (!item.isVisible) {
            return (
              <th
                key={Math.random()}
                className={`text-left p-2 py-4 font-semibold text-base  ${
                  item.width ? item.width : 'w-1/5'
                }  `}
              >
                {item.name}
              </th>
            );
          }
          if (item.isVisible === user.type) {
            return (
              <th
                key={Math.random()}
                className={`text-left p-2 py-4 font-semibold text-base  ${
                  item.width ? item.width : 'w-1/5'
                }  `}
              >
                {item.name}
              </th>
            );
          }
          return null;
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
