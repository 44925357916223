import 'tailwindcss/tailwind.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { StoreProvider } from 'easy-peasy';
import { PersistGate } from 'redux-persist/integration/react';
import { ApolloProvider } from '@apollo/client';

import theme from './utils/theme';
import apolloClient from './utils/apolloClient';
import { store, persistor } from './store';
import Routes from './utils/routes';
import { Loading } from './components/elements';

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <Routes />
          </ThemeProvider>
        </StoreProvider>
      </PersistGate>
    </ApolloProvider>
  );
};

export default App;
