import { TiTick } from 'react-icons/ti';

import image from '../assets/medical.svg';

const CardItem = ({ title, features, reverse }) => (
  <div
    className={`flex items-center  rounded-3xl  mb-36 flex-col md:${
      reverse ? 'flex-row-reverse' : 'flex-row'
    } `}
  >
    <div className="md:w-6/12 w-full flex justify-center">
      <img className="w-8/12" src={image} alt="Iridology" />
    </div>
    <div className="md:mx-12 md:w-6/12">
      <h1 className="text-5xl font-bold title-font mt-4 text-green-500  pb-1 ">
        {title}
      </h1>
      <div className="mt-8">
        {features.map((item) => (
          <div className="mt-4 px-2 md:w-full" key={item.id}>
            <div className="w-full flex">
              <span className="inline-block mt-2 text-center bg-green-500 rounded-full w-4 h-4 mr-2" />
              <p className="leading-relaxed text-medium text-xl ">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

const Card = ({ features1, features2 }) => (
  <section className="text-gray-600 body-font mt-36">
    <div className="flex justify-center">
      <div className="flex justify-center   flex-col  w-full px-8">
        <CardItem
          title="What iridology can show?"
          features={features1}
          reverse
        />
        <CardItem title="What iridology can't show?" features={features2} />
      </div>
    </div>
  </section>
);

export default Card;
