const services = [
  {
    id: 1,
    title: 'Medical Advices & Check Ups',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/2.jpg',
  },
  {
    id: 2,
    title: 'Trusted Medical Treatment ',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/6.jpg',
  },
  {
    id: 3,
    title: 'Emergency Help Available 24/7',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/3.jpg',
  },
  {
    id: 4,
    title: 'Medical Research Professionals',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/4.jpg',
  },
  {
    id: 5,
    title: 'Quality Care For Every Patient',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/8.jpg',
  },
  {
    id: 6,
    title: 'Only Qualified Doctors',
    image: 'http://7oroof.com/demos/medcity/assets/images/services/5.jpg',
  },
];

const Services = () => (
  <>
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="text-4xl font-bold  text-green-600 mb-8">
            IRIS Center Has Touched The Lives Of Patients & Providing Care For
            The Sickest In Our Community.
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            IRIS Center has been present in Europe since 1990, offering
            innovative solutions, specializing in medical services for treatment
            of medical infrastructure. With over 100 professionals actively
            participates in numerous initiatives aimed at creating sustainable
            change for patients!
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          {services.map((item) => (
            <div className="lg:w-1/3 sm:w-1/2 p-4 " key={item.id}>
              <div className="flex relative">
                <img
                  alt="gallery"
                  className="absolute inset-0 w-full h-full object-cover object-center"
                  src={item.image}
                />
                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105">
                  <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                    {item.title}
                  </h1>
                  <p className="leading-relaxed">
                    IRIS has been present in Europe since 1990, offering
                    innovative solutions, specializing in medical services for
                    treatment of medical infrastructure.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  </>
);

export default Services;
